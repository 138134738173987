import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { IoMdClose } from "react-icons/io";
import Classes from '../asset/styles/AdminEditAuditModal.module.css'
import Select from 'react-select';

const AdminEditAuditModal = ({show,handleClose}) => {
    const options = [
        { value: 'Active', label: 'Active' },
        { value: 'Inactive', label: 'Inactive' },
      ];
      const [selected,setSelected] = useState('')
  return (
    <>
       <Modal
      size='md'
      centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={`cmnModal`}
      >
        
        <Modal.Body>
          <div className={`${Classes.modalHeader} text-end`}>
            <button className={`${Classes.modalCloser}`} onClick={handleClose}><IoMdClose /></button>
          </div>
          <h5 className={`${Classes.modalTitle} m-0 text-center roboto mt-3 text-uppercase`}>Edit Audit</h5>


          <div className={`${Classes.singleInpHolder} mt-3`}>
             <label for="saleAddress" className={`${Classes.inputLabel} orbitron`}>Sale Address</label><br/>
              <input type="text" id="saleAddress" name="saleAddress" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-3`}>
             <label for="audit" className={`${Classes.inputLabel} orbitron`}>Audit</label><br/>
              <input type="text" id="audit" name="audit" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-3`}>
             <label for="kyc" className={`${Classes.inputLabel} orbitron text-uppercase`}>KYC</label><br/>
              <input type="text" id="kyc" name="kyc" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-3`}>
             <label for="kyc" className={`${Classes.inputLabel} orbitron text-uppercase`}>SAFU</label><br/>
              <input type="text" id="kyc" name="kyc" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-3`}>
             <label for="doxx" className={`${Classes.inputLabel} orbitron text-uppercase`}>doxx</label><br/>
              <input type="text" id="doxx" name="doxx" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="presaleRate" className={`${Classes.inputLabel} orbitron`}>Status</label><br/>
             <Select className={`mt-3`}
             placeholder={`Ex. Select Token Type`}
             name='token'
             classNamePrefix={`cmnSelect`}
              onChange={(e) => setSelected(e)}
        value={selected ? selected : null}
        options={options}
      />
              </div>
              
              <div className={`text-center w-100 mt-4`}>
              <button className={`${Classes.whiteBtn} poppins`} onClick={() => handleClose()}>Save</button>
              </div>
         
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminEditAuditModal
