import React from "react";
import Classes from "../asset/styles/Footer.module.css";
import { Images } from "../data/Images";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-scroll";

const Footer = () => {
  const location = useLocation();
  const footerLink = [
    {
      id: 1,
      icon: null,
      name: "launching solution",
      subItems: [
        {
          id: 1,
          name: "Launchpad List",
          linkTo: "/launchpad-list",
        },
        {
          id: 2,
          name: "Private Sale List",
          linkTo: "/private-sale-list",
        },
        {
          id: 3,
          name: "Premium Launch",
          linkTo: "/",
          scrollTo: "#premiumSale",
        },
        {
          id: 4,
          name: "Token Lock",
          linkTo: "/token-lock",
        },
      ],
    },
    {
      id: 2,
      icon: null,
      name: "documents",
      subItems: [
        {
          id: 1,
          name: "Whitepaper",
          linkTo: "/",
        },
        {
          id: 2,
          name: "Terms of Use",
          linkTo: "/",
        },
        {
          id: 3,
          name: "Privacy Policy",
          linkTo: "/",
        },
      ],
    },
    {
      id: 2,
      icon: null,
      name: "resources",
      subItems: [
        {
          id: 1,
          name: "TELEGRAM",
          linkTo: "/",
        },
        {
          id: 2,
          name: "MEDIUM",
          linkTo: "/",
        },
        {
          id: 3,
          name: "LINKEDIN",
          linkTo: "/",
        },
        {
          id: 3,
          name: "TWITTER",
          linkTo: "/",
        },
      ],
    },
  ];
  const handleLinkClick = (e) => {
    e.preventDefault();
    window.location.href = `${window.location.origin}/#premiumSale`;
    setTimeout(() => {
      window.history.replaceState(null, null, " ");
    }, 2000);
    console.log("oooooooooooooooooooo");
  };
  return (
    <>
      <Container className={`custom_container px-0`} id="footer">
        <div className={`${Classes.footer} px-2 px-sm-3 px-md-4 py-4`}>
          <Row className={`h-100`}>
            <Col lg={2} className={`mb-4 mb-lg-0 text-center text-sm-start`}>
              <img
                src={Images.logo}
                className={` ${Classes.footer_logo} img-fluid`}
                alt=""
              />
            </Col>

            <Col lg={10} className={``}>
              <div
                className={`${Classes.footer__topFlex} pb-2 d-flex justify-content-center justify-content-sm-between align-items-center flex-wrap gap-3`}
              >
                <p className={`${Classes.footer__topTitle} m-0 violet_sans`}>
                  Contact For Launching
                </p>
                <button
                  className={`${Classes.footer_btn} text-uppercase roboto py-2 px-3`}
                >
                  Telegram Consulting
                </button>
              </div>
              <Row className={`mt-3`}>
                {footerLink?.map((item) => (
                  <Col sm={6} md={4} className={`mb-4 mb-md-0`}>
                    {console.log(item, "itemitem")}
                    <h6
                      className={`${Classes.footer__linkTitle} m-0 roboto text-uppercase`}
                    >
                      {item.name}
                    </h6>
                    <ul className={`${Classes.footer_ul}`}>
                      {item?.subItems?.map((innerItems) => (
                        <li
                          className={`${Classes.footer_li} ${
                            item.name === "launching solution" ||
                            item.name === "documents"
                              ? Classes.footer_triangleLink
                              : Classes.footer_arrowLink
                          } position-relative mt-4`}
                        >
                          {innerItems?.scrollTo ? (
                            <>
                              
                             
                                <NavLink
                                  className={`${Classes.footerNavLinks}`}
                                  to={`${innerItems?.linkTo}`}
                                  onClick={handleLinkClick}
                                >
                                  {" "}
                                  {innerItems.name}
                                </NavLink>
                             
                            </>
                          ) : (
                            <NavLink
                              className={`${Classes.footerNavLinks}`}
                              to={innerItems?.linkTo}
                            >
                              {" "}
                              {innerItems.name}
                            </NavLink>
                          )}
                        </li>
                      ))}
                    </ul>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row className={`mt-4`}>
            <Col xs={3} lg={2} className={``}>
              <p className={` ${Classes.footer__rightsText} m-0`}>
                &#169; 2024
              </p>
            </Col>
            <Col xs={9} lg={10} className={``}>
              <p
                className={` ${Classes.footer__rightsText} m-0 text-uppercase  `}
              >
                All Rights Reserved by Solmax
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Footer;
