import React from 'react'
import { Modal } from 'react-bootstrap'
import { IoMdClose } from "react-icons/io";
import Classes from '../asset/styles/AdminEditCMSModal.module.css'

const AdminEditCMSModal = ({show,handleClose}) => {
  return (
    <>
       <Modal
      size='md'
      centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={`cmnModal`}
      >
        <Modal.Body>
          <div className={`${Classes.modalHeader} text-end`}>
            <button className={`${Classes.modalCloser}`} onClick={handleClose}><IoMdClose /></button>
          </div>
          <h5 className={`${Classes.modalTitle} m-0 text-center roboto mt-3 text-uppercase`}>Edit CMS Settings</h5>


          <div className={`${Classes.singleInpHolder} mt-3`}>
             <label for="identifier" className={`${Classes.inputLabel} orbitron`}>Identifier</label><br/>
              <input type="text" id="identifier" name="identifier" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-3`}>
             <label for="title" className={`${Classes.inputLabel} orbitron`}>Title</label><br/>
              <input type="text" id="title" name="title" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-3`}>
             <label for="description" className={`${Classes.inputLabel} orbitron`}>Token Value</label><br/>
             <textarea className={`${Classes.textArea} w-100 mt-2 p-2 inter_font`} id="description" name="description" rows={3} cols={50} />
              </div>
              <div className={`text-center w-100 mt-4`}>
              <button className={`${Classes.whiteBtn} poppins`} onClick={() => handleClose()}>Save</button>
              </div>
         
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminEditCMSModal
