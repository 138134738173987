import React, { useState } from 'react'
import AdminLayout from '../../layout/AdminLayout'
import { Pagination } from 'react-bootstrap'
import { commonData } from '../../data/CommonData'
import Classes from '../../asset/styles/AdminCMSSettings.module.css'
import AdminEditCMSModal from '../../Modals/AdminEditCMSModal'

const AdminCMSSettings = () => {
    const [editCMS,setEditCMS] = useState(false)
    const handleShowEditCMS = () => setEditCMS(true)
    const handleCloseEditCMS = () => setEditCMS(false)
  return (
    <>
    <AdminLayout>
    <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
          <h2
            className={`${Classes.launchpad__title} text-uppercase aboro_font`}
          >
            CMS Settings
          </h2>

          <div className={`${Classes.cmnTableHolder}`}>
            <table className={`${Classes.tokenCmnTable} mt-5`}>
              <thead>
                <tr>
                  <th className="pb-3">Identifier</th>
                  <th className="pb-3">Title</th>
                  <th className="pb-3">Content</th>
                  <th className="pb-3"></th>
                </tr>
              </thead>
              <tbody>
               
                  <tr className={`${Classes.gradientBorder}`}>
                   
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                        Standards
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                        Standards
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                       Mint standard tokens on Solana.
                      </p>
                    </td>
                    <td className={`text-end`}>
                     <button className={`${Classes.kycViewButton} border-0 outline-0 p-3 pointer position-relative`} onClick={() => handleShowEditCMS()}>Edit</button>
                    </td>
                  </tr>

                  <tr className={`${Classes.gradientBorder}`}>
                   
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                        Standards
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                        Standards
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                       Mint standard tokens on Solana.
                      </p>
                    </td>
                    <td className={`text-end`}>
                     <button className={`${Classes.kycViewButton} border-0 outline-0 p-3 pointer position-relative`} onClick={() => handleShowEditCMS()}>Edit</button>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
          <Pagination  />
          </div>

          




        </AdminLayout>  

        {/* modal content */}
                <AdminEditCMSModal show={editCMS} handleClose = {handleCloseEditCMS} />
        {/* end of modal content */}
    </>
  )
}

export default AdminCMSSettings
