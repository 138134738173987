import React, { useEffect, useRef, useState } from 'react'
import Classes from '../asset/styles/ConnectWallet.module.css'
import ConnectWalletModal from '../Modals/ConnectWalletModal';


const ConnectWallet = ({wallet,setWallet}) => {
    const scrollRef = useRef(null);
    const [walletAddress,setWalletAddress] = useState(null)
    // modal states
    const [show,setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    useEffect(() => {
        const preventDefault = (e) => {
          e.preventDefault();
        };
    
        const div = scrollRef.current;
        if (div) {
          div.addEventListener('wheel', preventDefault, { passive: false });
          div.addEventListener('scroll', preventDefault, { passive: false });
        }
    
        return () => {
          if (div) {
            div.removeEventListener('wheel', preventDefault, { passive: false });
            div.removeEventListener('scroll', preventDefault, { passive: false });
          }
        };
      }, []);

  return (
    <>
    <div ref={scrollRef} className={`${Classes.connectWalletHolder} d-flex justify-content-center align-items-center`}>
      <h6 className={`${Classes.connectWalletHint} m-0 text-center pointer`} onClick={handleShow}>Connect Wallet to Continue</h6>
    </div>

    {/* modal content */}

    <ConnectWalletModal show={show} handleClose={handleClose} wallet={wallet} setWallet={setWallet} />
    </>
  )
}

export default ConnectWallet
