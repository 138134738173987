import React from 'react'
import AdminLayout from '../../layout/AdminLayout'
import { commonData } from '../../data/CommonData'
import Pagination from '../../components/Pagination'
import Classes from '../../asset/styles/AdminCreatedLaunchpadList.module.css'
import { useNavigate } from 'react-router-dom'

const AdminCreateLaunchpadList = () => {
    const navigate = useNavigate()
  return (
    <>
       <AdminLayout>
    <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
          <h2
            className={`${Classes.launchpad__title} text-uppercase aboro_font`}
          >
            Created Launchpad List
          </h2>

          <div className={`${Classes.cmnTableHolder}`}>
            <table className={`${Classes.tokenCmnTable} mt-5`}>
              <thead>
                <tr>
                  <th className="pb-3">Sale Address</th>
                  <th className="pb-3">Symbol</th>
                  <th className="pb-3">Type</th>
                  <th className="pb-3">Action</th>
                </tr>
              </thead>
              <tbody>
                {commonData.adminCreatedLaunchpadTable?.map((item,i) => (
                  <tr className={`${Classes.gradientBorder}`}>
                   
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                      0xfbb7b7d1792af82bf900fa7dc23fe2e92caa2e3e
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                        ADP
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                        Private
                      </p>
                    </td>
                    <td>
                     <button className={`${Classes.kycViewButton} border-0 outline-0 pointer position-relative`} onClick={() => navigate('/admin-launchpad-detail')}>View</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination  />
          </div>
        </AdminLayout>  
    </>
  )
}

export default AdminCreateLaunchpadList
