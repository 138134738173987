/** npm import */

import React, { useEffect, useState } from "react";

/** local files import */

import Layout from "../../layout/Layout";
import BalanceDetails from "../../components/BalanceDetails";
import { Images } from "../../data/Images";
import Classes from '../../asset/styles/FailLaunch.module.css'
import { commonData } from "../../data/CommonData";
import { ImPlus } from "react-icons/im";
import LaunchingTable from '../../components/LaunchingTable'
import CalendarModal from "../../Modals/CalendarModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NavLink, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import ConnectWallet from "../../components/ConnectWallet";
import { Col, Row } from "react-bootstrap";

const CreateFairLaunch = () => {
    const navigate = useNavigate()
  const [currency,setCurrency] = useState(['sol','usdt','usdc'])
  const [selectedCurrency,setSelectedCurrency] = useState('sol')
  const [fee,setFee] = useState(1)
  const [tokenAddress,setStokenAddress] = useState('')
  const [steps,setSteps] = useState(1)
  const [whiteList,setWhiteList] = useState(false)
  const [listOption,setListOption] = useState('manual')
  const [startDate,setStartDate] = useState(new Date())
  const [endDate,setEndDate] = useState(new Date())
  const [vestingCont,setVestingCont] = useState(false)
  const [approve,setApprove] = useState(false)
  const [wallet,setWallet] = useState(true)
  const [calculatedValue,setCalculatedValue] = useState(5)

  // calendar modal state

  const [showCalendar, setShowCalendar] = useState(false);

  const handleCloseCalendar = () => setShowCalendar(false);
  const handleShowCalendar = () => setShowCalendar(true);



  // form validation

  const [formData,setFormData] = useState({
    tokenAddress : '',
    presaleRate : '',
    whiteList : '',
    softCap : '',
    hardCap : '',
    minimumBuy : '',
    maximumBuy : '',
    listingOptions : listOption,
    lockingDays : '',
    listingRate : '',
    liquidity : '',
    startTime : startDate,
    endTime : endDate,
    vestCont : vestingCont,
    vestingPeriod : '',
    vestingRewards : '',
    description : '',
    logoUrl : '',
    bannerUrl : '',
    website : '',
    yVideo : '',
    twitter : '',
    telegram : '',
    discord : '',
    github : '',
    instagram : '',
    reddit : ''
  })
  const [errors,setError] = useState({})

  const handleChange = (e) => {
    const {name,value} = e.target;
    setFormData({...formData,[name] : value})
  } 
  console.log(formData,'dfsafasfasfsa');

  const handleIncreateStep = () => {
    const err ={}   
    
    if(steps < 7) setSteps(newStep => newStep + 1)
      else if(steps >= 7) {
        setSteps(1)
    navigate('/launchpad-detail')
  }
  }

  useEffect(() =>{
    window.scroll(0,0)
  },[])
  return (
    <>
    <Layout>
      <div className={``}>
      <section className="custom_section position-relative">
      <img src={Images.lighting} className={`${Classes.absLighting} img-fluid`} alt="" />
      {wallet &&
     <ConnectWallet wallet={wallet} setWallet={setWallet} />
    }
      {/* <Loader/> */}
      <BalanceDetails/>

      <div className={`${Classes.customFluidContainer}`}>
            
            <div className={`${Classes.shapeSection}`}>
              <Row className={`position-relative h-100`}>
                <Col lg={5} md={5} xl={4} className={``}>
                  <div className={`${Classes.createStepsHolder} py-3 px-4 h-100`}>
                    {commonData?.createLaunchpadSteps.map((item,index) => 
                    <div className={`${Classes.singleStep} d-flex justify-content-start align-items-start gap-3 mb-1`}>
                      <div className={`${Classes.stepIndicator} d-flex flex-column justify-content-start align-items-center gap-1`}>
                      <i className={`fa-solid fa-circle ${steps === (index + 1) ? Classes.stepRound : Classes.stepRoundBlur}`}></i>
                      <div className={`${steps === (index + 1) ? Classes.stepLiner : Classes.stepLinerBlur}`}></div>
                      </div>

                      <div className={`${Classes.stepsHintHolder} flex-grow-1`}>
                        <h5 className={`${steps === (index + 1) ? Classes.stepTitle : Classes.stepsTitleBlur} ${Classes.resStepTitle} m-0 poppins`}>{item.title}</h5>
                        <p className={`${steps === (index + 1) ? Classes.stepHint : Classes.stepsHintBlur} ${Classes.stepsWidthAlign} ${Classes.resStepHint} m-0 poppins`}>{item.hint}</p>
                      </div>
                    </div>
                    )}

                    <p className={`${Classes.stepsDesc} my-5 m-0`}>Disclaimer: The information provided shall not in any way constitute a recomendation as to whether you should invest in any product discussed. We accept no liability for any loss occasioned to any person acting or refraining from action as a result of any material provided or published.</p>

                  </div>
                </Col>

                <Col lg={7} md={7} xl={8} className={` p-3 p-sm-4 h-100`}>
                <Row>
                    <Col lg={11}>
                  <div className={`d-flex justify-content-between align-items-center `}>
                <h1
              className={`${Classes.cmnBigTitle} m-0 text-uppercase aboro_font`}>Create Fair Launchpad</h1>
              <button className={`${Classes.gradientBtn} border-0 outline-0 py-1 px-2 py-sm-2 px-sm-3 pointer me-md-5`} onClick={() => navigate('/token-creation')}><ImPlus /></button>
              </div>
              </Col>
                  </Row>
              {steps <= 3 && 
              <p className={`${Classes.robotoHint} m-0 roboto mt-3`}>(*) is required field.</p>
              }

{steps === 1 && 
<>
              <label for="tokenAddress" className={`${Classes.inputLabel} roboto mt-4`}>Token Address *</label><br/>
              <input type="text" id="tokenAddress" name="tokenAddress" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />

              <p className={`${Classes.formDescHint} m-0 roboto mt-3`}>Pool creation fee: 1 SOL</p>
              <div className={`${Classes.tokenDetails} mt-4`}>
                <div className={`d-flex justify-content-between align-items-center mb-2`}>
                  <p className={`${Classes.radioHint} m-0`}>Name :</p>
                  <p className={`${Classes.radioHint} m-0`}>SOL</p>
                </div>
                <div className={`d-flex justify-content-between align-items-center mb-2`}>
                  <p className={`${Classes.radioHint} m-0`}>Symbol :</p>
                  <p className={`${Classes.radioHint} m-0`}>SOL</p>
                </div>
                <div className={`d-flex justify-content-between align-items-center mb-2`}>
                  <p className={`${Classes.radioHint} m-0`}>Decimals :</p>
                  <p className={`${Classes.radioHint} m-0`}>18</p>
                </div>
              </div>

              <label for="currency" className={`${Classes.inputLabel} roboto mt-4`}>Currency</label>

              <div className={`${Classes.tabHolder} d-flex justify-content-start align-items-center gap-2 mt-3`}>
                
              <button className={`${selectedCurrency === 'sol' ? Classes.tabBtnActive : Classes.tabBtn} text-uppercase d-flex justify-content-center align-items-center`} onClick={() => setSelectedCurrency('sol')}>
                  <p className={`${selectedCurrency === 'sol' ? Classes.tabBtnActiveText : Classes.tabBtnText} text-center`}>sol</p>
                  </button>
                  <button className={`${selectedCurrency === 'usdt' ? Classes.tabBtnActive : Classes.tabBtn} text-uppercase d-flex justify-content-center align-items-center`} onClick={() => setSelectedCurrency('usdt')}>
                  <p className={`${selectedCurrency === 'usdt' ? Classes.tabBtnActiveText : Classes.tabBtnText} text-center`}>usdt</p>
                  </button>
                  <button className={`${selectedCurrency === 'usdc' ? Classes.tabBtnActive : Classes.tabBtn} text-uppercase d-flex justify-content-center align-items-center`} onClick={() => setSelectedCurrency('usdc')}>
                  <p className={`${selectedCurrency === 'usdc' ? Classes.tabBtnActiveText : Classes.tabBtnText} text-center`}>usdc</p>
                  </button>
              </div>

              <p className={`${Classes.robotoHint} m-0 roboto mt-3`}>Users will pay with LINEAETH for your token</p>

              <label for="tokenAddress" className={`${Classes.inputLabel} roboto mt-4`}>Fee Options</label>


             <div className={`${Classes.radioHolder} mt-3`}>
              <button className={`${Classes.singleRadio} d-flex justify-content-start align-items-center gap-3 mb-3 pointer`} onClick={() => setFee(1)}>
              <i className={`fa-solid fa-square ${fee === 1 ? Classes.radioCheckerActive : Classes.radioChecker}`} />
              <p className={`${Classes.radioHint}`}>2% <b>SOL</b> + 2 token <b>SOL</b></p>
              </button>

              <button className={`${Classes.singleRadio} d-flex justify-content-start align-items-center gap-3 pointer`} onClick={() => setFee(2)}>
              <i className={`fa-solid fa-square ${fee === 2 ? Classes.radioCheckerActive : Classes.radioChecker}`} />
              <p className={`${Classes.radioHint}`}>5% <b>SOL</b> raised (recommended)</p>
              </button>
             </div>
             <button className={`${Classes.gradientBtn} border-0 outline-0 w-100 py-3 mt-5`} onClick={handleIncreateStep}>
              <h5 className={`${Classes.stepTitle} roboto m-0`}>Approve</h5>
              <p className={`${Classes.stepsDesc} roboto m-0`}>(For auto listing, after you finalize the pool your token will be auto listed on DEX)</p>
             </button>
             </>
             }


             {steps === 2 && 
             <>
             <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="presaleRate" className={`${Classes.inputLabel} roboto`}>Total Selling Amount *</label><br/>
              <input type="number" id="presaleRate" name="presaleRate" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
              <span className={`cmnErrorHint`}>{errors?.presaleRate && errors?.presaleRate}</span>
              </div>

              {/* <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="whiteList" className={`${Classes.inputLabel} roboto`}>Whitelist</label><br/>
             <div className={`${Classes.radioHolder} mt-3`}>
              <button className={`${Classes.singleRadio} d-flex justify-content-start align-items-center gap-3 mb-3 pointer`} onClick={() => setWhiteList(false)}>
              <i className={`fa-solid fa-square ${!whiteList ? Classes.radioCheckerActive : Classes.radioChecker}`} />
              <p className={`${Classes.radioHint}`}>Disable</p>
              </button>

              <button className={`${Classes.singleRadio} d-flex justify-content-start align-items-center gap-3 pointer`} onClick={() => setWhiteList(true)}>
              <i className={`fa-solid fa-square ${whiteList ? Classes.radioCheckerActive : Classes.radioChecker}`} />
              <p className={`${Classes.radioHint}`}>Enable</p>
              </button>
             </div>

              </div> */}


              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="softCap" className={`${Classes.inputLabel} roboto`}>Soft Cap *</label><br/>
              <input type="number" id="softCap" name="softCap" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
              </div>

              {/* <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="hardCap" className={`${Classes.inputLabel} roboto`}>Hard Cap *</label><br/>
              <input type="number" id="hardCap" name="hardCap" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" onChange={handleChange} />
              <span className={`cmnErrorHint`}>{errors?.hardCap && errors?.hardCap}</span>
              </div> */}


              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="minimumBuy" className={`${Classes.inputLabel} roboto`}>Minimum Buy *</label><br/>
              <input type="number" id="minimumBuy" name="minimumBuy" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="maximumBuy" className={`${Classes.inputLabel} roboto`}>Maximum Buy(LineaETH) *</label><br/>
              <input type="number" id="maximumBuy" name="maximumBuy" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off"  />
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="minimumBuy" className={`${Classes.inputLabel} roboto`}>Locking Days *</label><br/>
              <input type="number" id="minimumBuy" name="minimumBuy" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="maximumBuy" className={`${Classes.inputLabel} roboto`}>Solmax Liquidity (51% Minimum) *</label><br/>
              <input type="number" id="maximumBuy" name="maximumBuy" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off"  />
              </div>

              {/* <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="listingOptions" className={`${Classes.inputLabel} roboto`}>Listing Options</label><br/>
             <div className={`d-flex justify-content-start align-items-center gap-3 mt-3`}>
             <button className={`${listOption === 'auto' ? Classes.tabBtnActive : Classes.tabBtn} text-uppercase d-flex justify-content-center align-items-center`} onClick={() => setListOption('auto')}>
                  <p className={`${listOption === 'auto' ? Classes.tabBtnActiveText : Classes.tabBtnText} text-center`}>Auto</p>
                  </button>
                  <button className={`${listOption === 'manual' ? Classes.tabBtnActive : Classes.tabBtn} text-uppercase d-flex justify-content-center align-items-center`} onClick={() => setListOption('manual')}>
                  <p className={`${listOption === 'manual' ? Classes.tabBtnActiveText : Classes.tabBtnText} text-center`}>Manual</p>
                  </button>
                  </div>
              </div> */}
              

              <p className={`${Classes.formDescHint} m-0 roboto mt-4`}>Enter the percentage of raised funds that should be allocated to Liquidity on (Min 51% Max 100%)</p>
              <p className={`${Classes.formDescHint} m-0 roboto mt-3`}>If I spend 1 LineaETH on how many tokens will I receive? Usually the amount is lower than presale rate to allow for higher listing price on </p>


{/* <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="lockingDays" className={`${Classes.inputLabel} roboto`}>Locking Days</label><br/>
              <input type="text" id="lockingDays" name="lockingDays" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" onChange={handleChange} />
               <span className={`cmnErrorHint`}>{errors?.lockingDays && errors?.lockingDays}</span>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="listingRate" className={`${Classes.inputLabel} roboto`}>Listing Rate Per LineaETH</label><br/>
              <input type="text" id="listingRate" name="listingRate" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" onChange={handleChange} />
               <span className={`cmnErrorHint`}>{errors?.listingRate && errors?.listingRate}</span>
              <p className={`${Classes.formDescHint} m-0 roboto mt-2`}>1 LineaETH = 1000</p>
              </div>

 <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="liquidity" className={`${Classes.inputLabel} roboto`}>Solmax Liquidity (51% Minimum)</label><br/>
              <input type="text" id="liquidity" name="liquidity" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" onChange={handleChange} />
               <span className={`cmnErrorHint`}>{errors?.liquidity && errors?.liquidity}</span>
             </div> */}
 

<div className={`${Classes.singleInpHolder} mt-4`}>
<label className={`${Classes.inputLabel} roboto`}>Select Start time & End time (UTC)</label><br/>
             <label for="startTime" className={`${Classes.inputLabel} roboto mt-2`}>Start time (UTC)</label><br/>
              <div className={`${Classes.dateInpHolder} d-flex justify-content-between align-items-center mt-3 position-relative`}>
<div className={`${Classes.datePickerHolder} d-flex justify-content-between align-items-center w-100`}>
<DatePicker selected={startDate} name="startTime" dateFormat="Pp" showTimeSelect onChange={(date) => setStartDate(date)} className={`p-2 roboto flex-grow-1`} />
<button className={`${Classes.gradientCalendarBtn} border-0 outline-0 px-2 pointer`} onClick={handleShowCalendar}><i class="fa-solid fa-calendar-days"/></button>
</div>
              </div>
             </div>


             <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="endTime" className={`${Classes.inputLabel} roboto mt-2`}>End time (UTC)</label><br/>
              <div className={`${Classes.dateInpHolder} d-flex justify-content-between align-items-center mt-3 position-relative`}>
<div className={`${Classes.datePickerHolder} d-flex justify-content-between align-items-center w-100`}>
<DatePicker selected={endDate} name='endTime' dateFormat="Pp" showTimeSelect onChange={(date) => setEndDate(date)} className={`p-2 roboto flex-grow-1`} />
<button className={`${Classes.gradientCalendarBtn} border-0 outline-0 px-2 pointer`} onClick={handleShowCalendar}><i class="fa-solid fa-calendar-days"/></button>
</div>
              </div>
             </div>

             <button className={`${Classes.singleRadio} d-flex justify-content-start align-items-center gap-3 pointer mt-2`} name="vestCont" onClick={() => setVestingCont(!vestingCont)}>
              <i className={`fa-solid fa-square ${vestingCont ? Classes.radioCheckerActive : Classes.radioChecker}`} />
              <p className={`${Classes.radioHint}`}>Using Vesting Contributor?</p>
              </button>

{vestingCont && 
<>
              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="vestingPeriod" className={`${Classes.inputLabel} roboto`}>Vesting Period Days</label><br/>
              <input type="number" id="vestingPeriod" name="vestingPeriod" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off"  />
             </div>

             <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="vestingRewards" className={`${Classes.inputLabel} roboto`}>Rewards % per Vesting Period</label><br/>
              <input type="number" id="vestingRewards" name="vestingRewards" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
             </div>
             </>
             }

</>
}


{/* additional info */}
{steps === 3 &&
<>
<div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="description" className={`${Classes.inputLabel} roboto`}>Description</label><br/>
              {/* <input type="text" id="description" name="description" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" /> */}
              <textarea className={`${Classes.textArea} w-100 mt-2 p-2 roboto`} id="description" name="description" rows={3} cols={50}  />
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="logoUrl" className={`${Classes.inputLabel} roboto`}>Logo URL*</label><br/>
              <input type="text" id="logoUrl" name="logoUrl" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
              <p className={`${Classes.formDescHint} m-0 roboto mt-2`}>URL must be end with a supported image extension .png .jpg.</p>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="bannerUrl" className={`${Classes.inputLabel} roboto`}>Banner URL*</label><br/>
              <input type="text" id="bannerUrl" name="bannerUrl" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
              <p className={`${Classes.formDescHint} m-0 roboto mt-2`}>URL must be end with a supported image extension .png .jpg.</p>
              </div>


              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="website" className={`${Classes.inputLabel} roboto`}>Website*</label><br/>
              <input type="text" id="website" name="website" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="yVideo" className={`${Classes.inputLabel} roboto`}>Youtube Video</label><br/>
              <input type="text" id="yVideo" name="yVideo" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
              <p className={`${Classes.formDescHint} m-0 roboto mt-2`}>Input your youtube URL</p>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="twitter" className={`${Classes.inputLabel} roboto`}>Twitter</label><br/>
              <input type="text" id="twitter" name="twitter" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="telegram" className={`${Classes.inputLabel} roboto`}>Telegram</label><br/>
              <input type="text" id="telegram" name="telegram" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
              <p className={`${Classes.formDescHint} m-0 roboto mt-2`}>URL must be end with a supported image extension .png .jpg.</p>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="discord" className={`${Classes.inputLabel} roboto`}>Discord</label><br/>
              <input type="text" id="discord" name="discord" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
              <p className={`${Classes.formDescHint} m-0 roboto mt-2`}>URL must be end with a supported image extension .png .jpg.</p>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="github" className={`${Classes.inputLabel} roboto`}>Github</label><br/>
              <input type="text" id="github" name="github" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="instagram" className={`${Classes.inputLabel} roboto`}>Instagram</label><br/>
              <input type="text" id="instagram" name="instagram" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="reddit" className={`${Classes.inputLabel} roboto`}>Reddit</label><br/>
              <input type="text" id="reddit" name="reddit" className={`${Classes.inputBox} roboto mt-3 w-100 p-2`} autoComplete="off" />
              </div>
</>
}
{/* end of additional info */}

{/* finish */}
{(steps >= 4) &&
<>

<div className={`pt-2`}>
<div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
  <p className={`${Classes.flexText} m-0 roboto`}>Token Name :</p>
  <p className={`${Classes.flexText} m-0 roboto`}>OLS</p>
</div>
<div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
  <p className={`${Classes.flexText} m-0 roboto`}>Token Symbol :</p>
  <p className={`${Classes.flexText} m-0 roboto`}>OLS</p>
</div>
<div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
  <p className={`${Classes.flexText} m-0 roboto`}>Token Decimals :</p>
  <p className={`${Classes.flexText} m-0 roboto`}>OLS</p>
</div>
<div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
  <p className={`${Classes.flexText} m-0 roboto`}>Presale Rate :</p>
  <p className={`${Classes.flexText} m-0 roboto`}>OLS</p>
</div>
<div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
  <p className={`${Classes.flexText} m-0 roboto`}>Sale Method :</p>
  <p className={`${Classes.flexText} m-0 roboto`}>OLS</p>
</div>
<div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
  <p className={`${Classes.flexText} m-0 roboto`}>Softcap :</p>
  <p className={`${Classes.flexText} m-0 roboto`}>OLS</p>
</div>
<div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
  <p className={`${Classes.flexText} m-0 roboto`}>Hardcap :</p>
  <p className={`${Classes.flexText} m-0 roboto`}>OLS</p>
</div>
<div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
  <p className={`${Classes.flexText} m-0 roboto`}>Minimum Buy :</p>
  <p className={`${Classes.flexText} m-0 roboto`}>OLS</p>
</div>
<div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
  <p className={`${Classes.flexText} m-0 roboto`}>Maximum Buy :</p>
  <p className={`${Classes.flexText} m-0 roboto`}>OLS</p>
</div>
<div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
  <p className={`${Classes.flexText} m-0 roboto`}>Start Time :</p>
  <p className={`${Classes.flexText} m-0 roboto`}>OLS</p>
</div>
<div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
  <p className={`${Classes.flexText} m-0 roboto`}>End Time :</p>
  <p className={`${Classes.flexText} m-0 roboto`}>OLS</p>
</div>
<div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center mt-3`}>
  <p className={`${Classes.flexText} m-0 roboto`}>Website :</p>
  <p className={`${Classes.flexText} m-0 roboto`}><NavLink className={`cmnNavLink`} target="_blank" to='https://google.com'>OLS</NavLink></p>
</div>

<div className={`${Classes.creationDisclaimer} p-2 d-flex justify-content-between align-items-center gap-3 mt-4`}>
<i className={`fa-solid fa-circle-exclamation ${Classes.redExcla}`} />
<p className={`${Classes.disclaimerHint} m-0 roboto text-center flex-grow-1`}>For tokens with burns, rebase or other special transfers please ensure that you have a way to whiteList multiple addresses or turn off the special transfer events (By setting fees to 0 for example for the duration of the presale)</p>
</div>
</div>
</>
}
{steps === 6 &&
<p className={`${Classes.disclaimerHint} m-0 roboto text-center flex-grow-1 mt-4`}>Need 5 to create launchpad.</p>
 }

{console.log(steps,'stepppy')}
{/* end of finish */}


{steps !== 1 &&
              <div className={`mt-5 ${Classes.dualBtnHolder} d-flex justify-content-center align-items-center gap-3 mb-3`}>
               
          <button className={`${Classes.violetBtn} px-5`} onClick={() => setSteps(newStep => newStep - 1)}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`} >Back</p></button>
        
          <button className={`${Classes.violetBtn} px-5`} onClick={handleIncreateStep}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>{steps < 4 ? 'Next' : steps === 4 ? 'Create' : steps === 5 ? 'Calculate' : steps === 6 ? `Deposit ${calculatedValue}` : 'Proceed to sale'}</p></button>
        </div>
         }
            
              </Col>
              </Row>

            </div>
            </div>

            <div className={`mt-5`}>
              <LaunchingTable/>
              </div>
      </section>
      </div>
      </Layout>



      {/* modals */}

      <CalendarModal show={showCalendar} handleClose={handleCloseCalendar} setStartDate={setStartDate} startDate={startDate} />
    </>
  )
}

export default CreateFairLaunch
