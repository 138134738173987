import React, { useState } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import Classes from "../../../asset/styles/AdminLaunchpad.module.css";
import { NavLink } from "react-router-dom";
import { commonData } from "../../../data/CommonData";
import { Images } from "../../../data/Images";
import { CiGlobe } from "react-icons/ci";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";

const AdminLaunchpad = () => {


  return (
    <>
      <AdminLayout>
        <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
          <h2
            className={`${Classes.launchpad__title} text-uppercase aboro_font`}
          >
            Launchpad and Privatesale List
          </h2>

          <div className={`${Classes.cmnTableHolder} pb-3`}>
            <table className={`${Classes.tokenCmnTable} mt-5`}>
              <thead>
                <tr>
                  <th className="pb-3">Name</th>
                  <th className="pb-3">Sale Type</th>
                  <th className="pb-3">Hardcap</th>
                  <th className="pb-3">Softcap</th>
                  <th className="pb-3">Badge</th>
                  <th className="pb-3">Status</th>
                  <th className="pb-3">Links</th>
                  <th className="pb-3">Starts in</th>
                  <th className="pb-3">Ends in</th>
                  <th className="pb-3">Edit</th>
                </tr>
              </thead>
              <tbody>
                
              <tr className={`${Classes.gradientBorder}`}>
                    <td className="py-2">
                      <div
                        className={`${Classes.tokenDetails} d-flex justify-content-start align-items-center gap-2`}
                      >
                        <img
                          src={Images.coinCat}
                          className={`${Classes.tableTokenImg} img-fluid`}
                          alt=""
                        />
                        <p
                          className={`${Classes.tableTokenName} poppins m-0 position-relative`}
                        >
                          Cat
                        </p>
                      </div>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative`}
                      >
                        Private sale
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative`}
                      >
                        1
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative`}
                      >
                       1
                      </p>
                    </td>
                    <td>
                      <div className={`d-flex justify-content-start align-items-center gap-2`}>
                        <NavLink to='' className={`cmnNavLink pointer ${Classes.indexTopper}`}>
                        <button className={`${Classes.badgeBtns} text-uppercase orbitron border-0 outline-0 rounded-5 pointer`}>k</button>
                        </NavLink>
                        <NavLink to='' className={`cmnNavLink pointer ${Classes.indexTopper}`}>
                        <button className={`${Classes.badgeBtns} text-uppercase orbitron border-0 outline-0 rounded-5 pointer`}>d</button>
                        </NavLink>
                      </div>
                    </td>
                    <td>
                      <div className={`${Classes.card__progressHolder}`}>
                        <div
                          className={`${Classes.card__progressBar} position-relative`}
                        >
                          <div
                            className={`${Classes.card__filledProgress}`}
                            style={{ width: `40%` }}
                          ></div>
                        </div>
                        <p
                          className={`${Classes.tableTokenHint} poppins m-0 position-relative text-center mt-2`}
                        >
                          0.0001/50
                        </p>
                      </div>
                    </td>
                    <td>
                      <div
                        className={`d-flex justify-content-start align-items-center gap-2`}
                      >
                        <NavLink to='' className={`cmnNavLink pointer ${Classes.indexTopper}`}>
                        <button
                          className={`${Classes.socialIconBtn} d-flex justify-content-center align-items-center pointer`}
                        >
                          <CiGlobe className={`${Classes.globeImg}`} />
                        </button>
                        </NavLink>
                        <NavLink to='' className={`cmnNavLink pointer ${Classes.indexTopper}`}>
                        <button
                          className={`${Classes.socialIconBtn} d-flex justify-content-center align-items-center pointer`}
                        >
                          <FaXTwitter />
                        </button>
                        </NavLink>
                        <NavLink to='' className={`cmnNavLink pointer ${Classes.indexTopper}`}>
                        <button
                          className={`${Classes.socialIconBtn} d-flex justify-content-center align-items-center pointer`}
                        >
                          <FaTelegramPlane />
                        </button>
                        </NavLink>
                      </div>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative`}
                      >
                       04/07/24 12:00:00
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative`}
                      >
                        06/07/24 12:00:00
                      </p>
                    </td>
                    <td>
                      <button className={`${Classes.tablePlainBtn}`}>
                        <p className={`${Classes.plainBtnText}`}>
                         Enable
                        </p>
                      </button>
                    </td>
                  </tr>

                  <tr className={`${Classes.gradientBorder}`}>
                    <td className="py-2">
                      <div
                        className={`${Classes.tokenDetails} d-flex justify-content-start align-items-center gap-2`}
                      >
                        <img
                          src={Images.coinCat}
                          className={`${Classes.tableTokenImg} img-fluid`}
                          alt=""
                        />
                        <p
                          className={`${Classes.tableTokenName} poppins m-0 position-relative`}
                        >
                          Cat
                        </p>
                      </div>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative`}
                      >
                        Private sale
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative`}
                      >
                        1
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative`}
                      >
                       1
                      </p>
                    </td>
                    <td>
                      <div className={`d-flex justify-content-start align-items-center gap-2`}>
                        <NavLink to='' className={`cmnNavLink pointer ${Classes.indexTopper}`}>
                        <button className={`${Classes.badgeBtns} text-uppercase orbitron border-0 outline-0 rounded-5 pointer`}>k</button>
                        </NavLink>
                        <NavLink to='' className={`cmnNavLink pointer ${Classes.indexTopper}`}>
                        <button className={`${Classes.badgeBtns} text-uppercase orbitron border-0 outline-0 rounded-5 pointer`}>d</button>
                        </NavLink>
                      </div>
                    </td>
                    <td>
                      <div className={`${Classes.card__progressHolder}`}>
                        <div
                          className={`${Classes.card__progressBar} position-relative`}
                        >
                          <div
                            className={`${Classes.card__filledProgress}`}
                            style={{ width: `40%` }}
                          ></div>
                        </div>
                        <p
                          className={`${Classes.tableTokenHint} poppins m-0 position-relative text-center mt-2`}
                        >
                          0.0001/50
                        </p>
                      </div>
                    </td>
                    <td>
                      <div
                        className={`d-flex justify-content-start align-items-center gap-2`}
                      >
                        <NavLink to='' className={`cmnNavLink pointer ${Classes.indexTopper}`}>
                        <button
                          className={`${Classes.socialIconBtn} d-flex justify-content-center align-items-center pointer`}
                        >
                          <CiGlobe className={`${Classes.globeImg}`} />
                        </button>
                        </NavLink>
                        <NavLink to='' className={`cmnNavLink pointer ${Classes.indexTopper}`}>
                        <button
                          className={`${Classes.socialIconBtn} d-flex justify-content-center align-items-center pointer`}
                        >
                          <FaXTwitter />
                        </button>
                        </NavLink>
                        <NavLink to='' className={`cmnNavLink pointer ${Classes.indexTopper}`}>
                        <button
                          className={`${Classes.socialIconBtn} d-flex justify-content-center align-items-center pointer`}
                        >
                          <FaTelegramPlane />
                        </button>
                        </NavLink>
                      </div>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative`}
                      >
                       04/07/24 12:00:00
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative`}
                      >
                        06/07/24 12:00:00
                      </p>
                    </td>
                    <td>
                      <button className={`${Classes.tablePlainBtn}`}>
                        <p className={`${Classes.plainBtnText}`}>
                         Disable
                        </p>
                      </button>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
          <div className={`text-center w-100 mt-5`}>
            <button className={`${Classes.violetBtn} px-2 px-sm-3 px-md-4`}>
              <p
                className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}
              >
                View More Pools
              </p>
            </button>
          </div>
        </div>
      </AdminLayout>
    </>
  );
};

export default AdminLaunchpad;
