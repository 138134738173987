import React, { useEffect } from 'react'
import Layout from '../../layout/Layout'
import { Images } from '../../data/Images'
import Classes from '../../asset/styles/LockDetail.module.css'
import { Col, Row } from 'react-bootstrap'
import BalanceDetails from '../../components/BalanceDetails'

const LockDetail = () => {
    useEffect(() =>{
        window.scrollTo(0,0)
      },[])
  return (
    <>
    <Layout>
    <section className="custom_section position-relative">
      <img src={Images.lighting} className={`${Classes.absLighting} img-fluid`} alt="" />
        <BalanceDetails/>

        <Row className={`mx-auto ${Classes.createToken__emptyRow} pb-5 position-relative`}>
        <Col lg={{span:8,offset:2}} xs={12} className={`px-lg-0 ${Classes.dash__emptyBorder}`}>
        <div className={`${Classes.tokenCreationHolder} px-2 px-sm-3 px-md-4 px-lg-5 py-5`}>
      <h2 className={`${Classes.launchpad__title} text-uppercase aboro_font`}>Lock Info</h2>

      <div className={`${Classes.flexHolder}`}>
            <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                <p className={`${Classes.flexLabel} m-0 roboto`}>Current Locked Amount</p>
                <p className={`${Classes.flexValue} roboto m-0`}>1</p>
            </div>
            <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                <p className={`${Classes.flexLabel} m-0 roboto`}>Token Address</p>
                <p className={`${Classes.flexValue} roboto m-0`}>0x7D9B40788bf48aF3DD6Fad6b758cF949e481dDA4</p>
            </div>
            <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                <p className={`${Classes.flexLabel} m-0 roboto`}>Token Name</p>
                <p className={`${Classes.flexValue} roboto m-0`}>Cat</p>
            </div>
            <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                <p className={`${Classes.flexLabel} m-0 roboto`}>Token Symbol</p>
                <p className={`${Classes.flexValue} roboto m-0 text-uppercase`}>LEDA</p>
            </div>
            <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                <p className={`${Classes.flexLabel} m-0 roboto`}>Token Decimals</p>
                <p className={`${Classes.flexValue} roboto m-0`}>10</p>
            </div>
            <h2 className={`${Classes.launchpad__subTitle} text-uppercase aboro_font mt-5`}>Lock Records</h2>
            <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                <p className={`${Classes.flexLabel} m-0 roboto`}>Owner</p>
                <p className={`${Classes.flexValue} roboto m-0`}>0x754a9c737dF3224B0A200408120c07F74Cef112f</p>
            </div>
            <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                <p className={`${Classes.flexLabel} m-0 roboto`}>Amount</p>
                <p className={`${Classes.flexValue} roboto m-0`}>10</p>
            </div>
            <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                <p className={`${Classes.flexLabel} m-0 roboto`}>Unlock Time(UTC)</p>
                <p className={`${Classes.flexValue} roboto m-0`}>14/07/2024</p>
            </div>
        </div>
        </div>

       
        </Col>
            </Row>
        {/* <LaunchingTable/> */}
            </section>
    </Layout>
      
    </>
  )
}

export default LockDetail
