import React from 'react'
import { Modal } from 'react-bootstrap';
import { IoMdClose } from "react-icons/io";
import Classes from '../asset/styles/AdminEditFee.module.css'

const AdminEditFeeModal = ({show,handleClose}) => {
  return (
    <>
       <Modal
      size='md'
      centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={`cmnModal`}
      >
        <Modal.Body>
          <div className={`${Classes.modalHeader} text-end`}>
            <button className={`${Classes.modalCloser}`} onClick={handleClose}><IoMdClose /></button>
          </div>
          <h5 className={`${Classes.modalTitle} m-0 text-center roboto mt-3 text-uppercase`}>Edit Fee Settings</h5>


          <div className={`${Classes.singleInpHolder} mt-3`}>
             <label for="feeName" className={`${Classes.inputLabel} orbitron`}>Fee Name</label><br/>
              <input type="text" id="feeName" name="feeName" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-3`}>
             <label for="currencyValue" className={`${Classes.inputLabel} orbitron`}>Currency Value</label><br/>
              <input type="text" id="currencyValue" name="currencyValue" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-3`}>
             <label for="tokenValue" className={`${Classes.inputLabel} orbitron`}>Token Value</label><br/>
              <input type="text" id="tokenValue" name="tokenValue" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>
              <div className={`text-center w-100 mt-4`}>
              <button className={`${Classes.whiteBtn} poppins`} onClick={() => handleClose()}>Save</button>
              </div>
         
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminEditFeeModal
