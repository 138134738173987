import React, { useContext, useState } from 'react'
import Classes from '../../asset/styles/AdminLogin.module.css'
import { Col, Row } from 'react-bootstrap'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Images } from '../../data/Images'
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { sidebarContext } from '../../Context/Context'
const AdminLogin = () => {
    const [passwordType,setPasswordType] = useState(false)
    const navigate = useNavigate()
  return (
    <>
      <div className={`${Classes.adminLogin} position-relative`}>
        <Row className='position-relative h-100 d-flex justify-content-center align-items-center'>
            <Col lg={4}>
               
                <div className={`${Classes.loginFormHolder} p-2 p-sm-4`}>
                <div className={`text-center`}>
                    <img src={Images.logo} className={`img-fluid`} alt='' />
                </div>
                <h2 className={`${Classes.login__title} text-uppercase aboro_font mt-4`}>Login</h2>
        <div className={`${Classes.titleDivider}`}></div>

        <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="email" className={`${Classes.inputLabel} orbitron`}>Email Address</label><br/>
              <input type="email" id="email" name="email" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex.John@gmail.com' autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
              <label for="password" className={`${Classes.inputLabel} orbitron`}>Password</label><br/>
              <div className={`${Classes.infoInpHolder} position-relative mt-3 d-flex justify-content-between align-items-center`}>
              <input type={passwordType ? `text` : `password`} id="password" name="password" className={`${Classes.passwordInputBox} inter_font p-2 w-100`} autoComplete="off" />
              <button className={`${Classes.gradientBtnSmall} border-0 outline-0 px-3 py-1 me-1`} onClick={() => setPasswordType(!passwordType)}>
                {!passwordType ?
                <FaEye fontSize={18} />
                :
                <FaEyeSlash fontSize={18} />
            }
                </button>
              </div>
              </div>
              <div className={`d-flex justify-content-end w-100`}>
              <NavLink to='/forgot-password' className={`${Classes.loginNavLink} text-end`} draggable={false}>
              <p className={`${Classes.fogotPwHint} m-0 poppins text-end mt-3 pointer`}>Forgot Password?</p>
              </NavLink>
              </div>

              <div className={`text-center mt-4`}>
                {/* <button className={`${Classes.loginGradBtn} border-0 outline-0 roboto px-5 py-2 pointer`}>Login</button> */}
                <button className={`${Classes.violetBtn} px-5`} onClick={() => navigate('/launchpad-admin')}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>Login</p></button>
              </div>
                </div>

            </Col>
        </Row>
      </div>
    </>
  )
}

export default AdminLogin
