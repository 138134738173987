import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Classes from '../asset/styles/CalendarModal.module.css'

const CalendarModal = ({show,handleClose,setStartDate,startDate}) => {


    const handleChangeDate = (newDate) => {
        setStartDate(newDate)
    }
    console.log(startDate,'startDatedd');
  return (
    <>
      <Modal
      centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={`cmnCalendarModal`}
      >
        <div className={`cmnModalHeader px-4 py-3 d-flex justify-content-between align-items-center`}>
            <h5 className={`cmnModalTitle`}>Select Start Date</h5>
            <button className={`cmnModalCloser p-2`} onClick={handleClose}><i class="fa-solid fa-xmark" /></button>
        </div>
        <Modal.Body>
            <div className={`d-flex justify-content-center flex-column align-items-center gap-4`}>
        <Calendar  value={startDate} onChange={handleChangeDate} className={``} />
        <button className={`${Classes.gradientBtn} border-0 outline-0 px-4 py-2`} onClick={handleClose}>Submit</button>

        </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CalendarModal
