import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import AdminLayout from '../../../layout/AdminLayout'
import Classes from '../../../asset/styles/AdminEditLaunchpad.module.css'

const AdminEditLaunchpad = () => {
    useEffect(() =>{
        window.scrollTo(0,0)
      },[])
  return (
    <>
      <AdminLayout>
      <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
      <Row className={`mx-auto ${Classes.createToken__emptyRow} justify-content-center`}>
      <Col lg={9} xs={12} className={`px-lg-0 ${Classes.dash__emptyBorder}`}>
      <div className={`${Classes.tokenCreationHolder} px-2 px-sm-3 px-md-4 px-lg-5 py-5`}>
    <h2 className={`${Classes.launchpad__title} text-uppercase aboro_font`}>Edit Launchpad</h2>

    <div className={`${Classes.singleInpHolder} mt-4`}>
           <label for="logoURL" className={`${Classes.inputLabel} orbitron`}>Logo URL</label><br/>
            <input type="text" id="logoURL" name="logoURL" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex.Ethereum' autoComplete="off" />
            <p className={`${Classes.robotoHint} m-0 inter_font mt-2`}>URL must be end with a supported image extension .png .jpg</p>
            </div>

            <div className={`${Classes.singleInpHolder} mt-4`}>
           <label for="bannerURL" className={`${Classes.inputLabel} orbitron`}>Banner URL </label><br/>
            <input type="text" id="bannerURL" name="bannerURL" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex.Ethereum' autoComplete="off" />
            <p className={`${Classes.robotoHint} m-0 inter_font mt-2`}>URL must be end with a supported image extension .png .jpg</p>
            </div>

            <div className={`${Classes.singleInpHolder} mt-4`}>
           <label for="website" className={`${Classes.inputLabel} orbitron`}>Website</label><br/>
            <input type="text" id="website" name="website" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex.Ethereum' autoComplete="off" />
            </div>

            <div className={`${Classes.singleInpHolder} mt-4`}>
           <label for="twitter" className={`${Classes.inputLabel} orbitron`}>Twitter</label><br/>
            <input type="text" id="twitter" name="twitter" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex.Ethereum' autoComplete="off" />
            </div>

            <div className={`${Classes.singleInpHolder} mt-4`}>
           <label for="telegram" className={`${Classes.inputLabel} orbitron`}>Telegram</label><br/>
            <input type="text" id="telegram" name="telegram" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex.Ethereum' autoComplete="off" />
            </div>

            <div className={`${Classes.singleInpHolder} mt-4`}>
           <label for="github" className={`${Classes.inputLabel} orbitron`}>Github</label><br/>
            <input type="text" id="github" name="github" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex.Ethereum' autoComplete="off" />
            </div>

            <div className={`${Classes.singleInpHolder} mt-4`}>
           <label for="instagram" className={`${Classes.inputLabel} orbitron`}>Instagram</label><br/>
            <input type="text" id="instagram" name="instagram" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex.Ethereum' autoComplete="off" />
            </div>

            <div className={`${Classes.singleInpHolder} mt-4`}>
           <label for="discord" className={`${Classes.inputLabel} orbitron`}>Discord</label><br/>
            <input type="text" id="discord" name="discord" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex.Ethereum' autoComplete="off" />
            </div>

            <div className={`${Classes.singleInpHolder} mt-4`}>
           <label for="reddit" className={`${Classes.inputLabel} orbitron`}>Reddit</label><br/>
            <input type="text" id="reddit" name="reddit" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex.Ethereum' autoComplete="off" />
            </div>

            <div className={`${Classes.singleInpHolder} mt-4`}>
           <label for="youtube" className={`${Classes.inputLabel} orbitron`}>Youtube Video</label><br/>
            <input type="text" id="youtube" name="youtube" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex.Ethereum' autoComplete="off" />
            </div>

            <div className={`${Classes.singleInpHolder} mt-4`}>
           <label for="description" className={`${Classes.inputLabel} orbitron`}>Discord</label><br/>
           <textarea className={`${Classes.textArea} w-100 mt-2 p-2 roboto`} id="description" name="description" placeholder='Ex: Lorem Ipsum' rows={3} cols={50} />
            </div>

            <div className={`mt-5 ${Classes.dualBtnHolder} d-flex justify-content-center align-items-center gap-3 mb-3`}>
        <button className={`${Classes.violetBtn} px-2 px-sm-3 px-md-4 px-lg-5`}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>Back</p></button>
       
        <button className={`${Classes.violetBtn} px-2 px-sm-3 px-md-4 px-lg-5`}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>Update</p></button>

      </div>
      </div>
      </Col>
      </Row>
      </div>
      </AdminLayout>
    </>
  )
}

export default AdminEditLaunchpad
