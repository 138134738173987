import React, { useEffect, useState } from 'react'
import AdminLayout from '../../layout/AdminLayout'
import Classes from '../../asset/styles/AdminKYCList.module.css'
import AdminKYCModal from '../../Modals/AdminKYCModal'
import { commonData } from '../../data/CommonData'
import Pagination from '../../components/Pagination'

const AdminKycList = () => {
    // modal state
    const [kyc,setKyc] = useState(false)
    const handleShowKyc = () => setKyc(true)
    const handleCloseKyc = () => setKyc(false)


    // const [filterArr,setFilterArr] = useState([]) 
    // const [lengthValue,setLengthValue] = useState(0)
    
  return (
    <>
      <AdminLayout>
      <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
          <h2
            className={`${Classes.launchpad__title} text-uppercase aboro_font`}
          >
            KYC List
          </h2>

          <div className={`${Classes.cmnTableHolder}`}>
            <table className={`${Classes.tokenCmnTable} mt-5`}>
              <thead>
                <tr>
                  <th className="pb-3">User Wallet Address</th>
                  <th className="pb-3">Status</th>
                  <th className="pb-3"></th>
                </tr>
              </thead>
              <tbody>
                  <tr className={`${Classes.gradientBorder}`}>
                   
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                        0xfbb7b7d1792af82bf900fa7dc23fe2e92caa2e3e
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                        No KYC
                      </p>
                    </td>
                    <td className={`text-end`}>
                     <button className={`${Classes.kycViewButton} border-0 outline-0 p-3 pointer position-relative`} onClick={() => handleShowKyc()}>View</button>
                    </td>
                  </tr>

                  <tr className={`${Classes.gradientBorder}`}>
                   
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                        0xfbb7b7d1792af82bf900fa7dc23fe2e92caa2e3e
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                        KYC
                      </p>
                    </td>
                    <td className={`text-end`}>
                     <button className={`${Classes.kycViewButton} border-0 outline-0 p-3 pointer position-relative`} onClick={() => handleShowKyc()}>View</button>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
          <Pagination  />
         
          </div>
      </AdminLayout>

      {/* modal content */}
        <AdminKYCModal show={kyc} handleClose={handleCloseKyc} />
      {/* end of modal content */}
    </>
  )
}

export default AdminKycList
