import React from 'react'
import Classes from '../asset/styles/AdminKYCModal.module.css'
import { IoMdClose } from "react-icons/io";
import { Modal } from 'react-bootstrap';

const AdminSettingModal = ({show,handleClose}) => {
  return (
    <>
      <Modal
      size='md'
      centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={`cmnModal`}
      >
        <Modal.Body>
          <div className={`${Classes.modalHeader} text-end`}>
            <button className={`${Classes.modalCloser}`} onClick={handleClose}><IoMdClose /></button>
          </div>
          <h5 className={`${Classes.modalTitle} m-0 text-center roboto mt-3 text-uppercase`}>Edit Setting</h5>


          <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="settingName" className={`${Classes.inputLabel} orbitron`}>Setting Name</label><br/>
              <input type="text" id="settingName" name="settingName" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="settingValue" className={`${Classes.inputLabel} orbitron`}>Setting Value</label><br/>
              <input type="text" id="settingValue" name="settingValue" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>
<div className={`d-flex justify-content-center align-items-center gap-3 w-100 mt-4`}>
              <button className={`${Classes.whiteBtn} poppins`} onClick={() => handleClose()}>Edit</button>
              </div>
         
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminSettingModal
