import React, { useState } from 'react'
import AdminLayout from '../../layout/AdminLayout'
import Classes from '../../asset/styles/AdminAuditList.module.css'
import { commonData } from '../../data/CommonData'
import Pagination from '../../components/Pagination'
import AdminEditAuditModal from '../../Modals/AdminEditAuditModal'

const AdminAuditList = () => {
  // modal state
  const [editAudit,setEditAudit] = useState(false)
  const handleShowEditAudit = () => setEditAudit(true)
  const handleCloseEditAudit = () => setEditAudit(false)
  return (
    <>
       <AdminLayout>
    <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
          <h2
            className={`${Classes.launchpad__title} text-uppercase aboro_font`}
          >
            Audit/KYC List
          </h2>

          <div className={`${Classes.cmnTableHolder}`}>
            <table className={`${Classes.tokenCmnTable} mt-5`}>
              <thead>
                <tr>
                  <th className="pb-3">S.No</th>
                  <th className="pb-3">Sale Address</th>
                  <th className="pb-3">Status</th>
                  <th className="pb-3"></th>
                </tr>
              </thead>
              <tbody>
                  <tr className={`${Classes.gradientBorder}`}>
                   
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                       1
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                        0xfbb7b7d1792af82bf900fa7dc23fe2e92caa2e3e
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                        Standard
                      </p>
                    </td>
                    <td className={`text-end`}>
                     <button className={`${Classes.kycViewButton} border-0 outline-0 p-3 pointer position-relative`} onClick={() => handleShowEditAudit()}>Edit</button>
                    </td>
                  </tr>

                  <tr className={`${Classes.gradientBorder}`}>
                   
                   <td>
                     <p
                       className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                     >
                      2
                     </p>
                   </td>
                   <td>
                     <p
                       className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                     >
                       0xfbb7b7d1792af82bf900fa7dc23fe2e92caa2e3e
                     </p>
                   </td>
                   <td>
                     <p
                       className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                     >
                       Active
                     </p>
                   </td>
                   <td className={`text-end`}>
                    <button className={`${Classes.kycViewButton} border-0 outline-0 p-3 pointer position-relative`} onClick={() => handleShowEditAudit()}>Edit</button>
                   </td>
                 </tr>
              </tbody>
            </table>
          </div>
          <Pagination  />
          </div>
        </AdminLayout>  

        {/* modal content */}
                <AdminEditAuditModal show={editAudit} handleClose={handleCloseEditAudit} />
        {/* end of modal content */}
    </>
  )
}

export default AdminAuditList
