
import React from 'react'
import { createContext, useState } from "react";

export const sidebarContext = createContext(null)


const GlobalState = ({children}) => {
    const [sidebar,setSidebar] = useState(true)
    const handleChange = () =>{
        setSidebar(!sidebar)
    }
  return (
    <sidebarContext.Provider value={{sidebar,handleChange}}>
      {children}
    </sidebarContext.Provider>
  )
}

export default GlobalState
