import React, { useState } from 'react'
import AdminLayout from '../../layout/AdminLayout'
import Classes from '../../asset/styles/AdminSettings.module.css'
import { commonData } from '../../data/CommonData'
import AdminSettingModal from '../../Modals/AdminSettingModal'
import Pagination from '../../components/Pagination'

const AdminSettings = () => {
    // modal state
    const [showSetting,setShowSetting] = useState(false)
    const handleShowSetting = () => setShowSetting(true)
    const handleCloseSetting = () => setShowSetting(false)
  return (
    <>
      <AdminLayout>
      <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
          <h2
            className={`${Classes.launchpad__title} text-uppercase aboro_font`}
          >
            Settings List
          </h2>

          <div className={`${Classes.cmnTableHolder}`}>
            <table className={`${Classes.tokenCmnTable} mt-5`}>
              <thead>
                <tr>
                  <th className="pb-3">Setting Name</th>
                  <th className="pb-3">Setting Value</th>
                  <th className="pb-3"></th>
                </tr>
              </thead>
              <tbody>
                  <tr className={`${Classes.gradientBorder}`}>
                   
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                        Introduction
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                        https://www.google.com/
                      </p>
                    </td>
                    <td className={`text-end`}>
                     <button className={`${Classes.kycViewButton} border-0 outline-0 p-3 pointer position-relative`} onClick={() => handleShowSetting()}>Edit</button>
                    </td>
                  </tr>

                  <tr className={`${Classes.gradientBorder}`}>
                   
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                        Introduction
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                        https://www.google.com/
                      </p>
                    </td>
                    <td className={`text-end`}>
                     <button className={`${Classes.kycViewButton} border-0 outline-0 p-3 pointer position-relative`} onClick={() => handleShowSetting()}>Edit</button>
                    </td>
                  </tr>

                  
              </tbody>
            </table>
          </div>
          <Pagination  />
         
          </div>
        </AdminLayout>

        {/* modal content */}
        <AdminSettingModal show={showSetting} handleClose={handleCloseSetting} />
        {/* end of modal content */}
    </>
  )
}

export default AdminSettings
