import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import { Images } from '../../data/Images'
import Classes from '../../asset/styles/MyLockDetail.module.css'
import { Col, Row } from 'react-bootstrap'
import BalanceDetails from '../../components/BalanceDetails'
import LaunchingTable from '../../components/LaunchingTable'
import Countdown from 'react-countdown';
import TransferOwnerShip from '../../Modals/TransferOwnerShip'

const MyLockDetail = () => {
    const [unlock,setUnlock] = useState(true)

    // modal state
    const [showTransferOwner,setTransferOwner] = useState(false)
    const handleShowTransfer = () => setTransferOwner(true)
    const handleCloseTransfer = () => setTransferOwner(false)


    const renderer = ({days,hours,minutes,seconds}) => {
        return(
          <div className={`${Classes.countdownRenderer} d-flex justify-content-center align-items-center`}>
        <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 position-relative roboto`}>{days < 10 ? `0${days}` : days}</p>
        <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 position-relative roboto`}>{hours < 10 ? `0${hours}` : hours}</p>
        <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 position-relative roboto`}>{minutes < 10 ? `0${minutes}` : minutes}</p>
        <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 position-relative roboto`}>{seconds < 10 ? `0${seconds}` : seconds}</p>
        </div>
      )
      }
      useEffect(() =>{
        window.scrollTo(0,0)
      },[])
  return (
    <>
    <Layout>
    <section className="custom_section position-relative">
      <img src={Images.lighting} className={`${Classes.absLighting} img-fluid`} alt="" />
        <BalanceDetails/>

        <Row className={`mx-auto ${Classes.createToken__emptyRow} pb-5 position-relative`}>
        <Col lg={{span:8,offset:2}} xs={12} className={`px-lg-0 ${Classes.dash__emptyBorder}`}>
        <div className={`${Classes.tokenCreationHolder} px-2 px-sm-3 px-md-4 px-lg-5 py-5`}>
      <h2 className={`${Classes.launchpad__title} text-uppercase aboro_font`}>My Lock Info</h2>

      <div className={`${Classes.mylock_countdownHolder} mb-5 mt-4`}>
        <p className={`${Classes.countdownTitle} text-uppercase m-0 mb-2 roboto text-center`}>unlock in</p>
      <Countdown date={Date.now() + 450000000} renderer={renderer} />
      </div>

      <div className={`${Classes.flexHolder}`}>
      <h2 className={`${Classes.launchpad__subTitle} text-uppercase aboro_font`}>Token Info</h2>
            <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                <p className={`${Classes.flexLabel} m-0 roboto`}>Token Address</p>
                <p className={`${Classes.flexValue} roboto m-0`}>0x7D9B40788bf48aF3DD6Fad6b758cF949e481dDA4</p>
            </div>
            <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                <p className={`${Classes.flexLabel} m-0 roboto`}>Token Name</p>
                <p className={`${Classes.flexValue} roboto m-0`}>Cat</p>
            </div>
            <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                <p className={`${Classes.flexLabel} m-0 roboto`}>Token Symbol</p>
                <p className={`${Classes.flexValue} roboto m-0 text-uppercase`}>LEDA</p>
            </div>
            <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                <p className={`${Classes.flexLabel} m-0 roboto`}>Token Decimals</p>
                <p className={`${Classes.flexValue} roboto m-0`}>10</p>
            </div>

      <h2 className={`${Classes.launchpad__subTitle} text-uppercase aboro_font mt-5`}>Lock Info</h2>
            <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                <p className={`${Classes.flexLabel} m-0 roboto`}>Total Amount Locked</p>
                <p className={`${Classes.flexValue} roboto m-0`}>1</p>
            </div>
            <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                <p className={`${Classes.flexLabel} m-0 roboto`}>Owner</p>
                <p className={`${Classes.flexValue} roboto m-0`}>0x754a9c737dF3224B0A200408120c07F74Cef112f</p>
            </div>
            <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                <p className={`${Classes.flexLabel} m-0 roboto`}>Lock Date</p>
                <p className={`${Classes.flexValue} roboto m-0`}>Sunday, Oct 08, 2024</p>
            </div>
        </div>

        <div className={`mt-5 ${Classes.dualBtnHolder} d-flex justify-content-center align-items-center gap-3 mb-3`}>
          <button className={`${Classes.violetBtn} px-2 px-sm-3`} onClick={() => {handleShowTransfer()}}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>Transfer Lock Ownership</p></button>
         
          <button className={`${Classes.violetBtn} px-2 px-sm-3`} disabled={unlock}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>Unlock</p></button>
        
        </div>
        </div>

       
        </Col>
            </Row>
            </section>
    </Layout>

    {/* modals */}
    <TransferOwnerShip show={showTransferOwner} handleClose={handleCloseTransfer} />
    {/* end of modals */}
      
    </>
  )
}

export default MyLockDetail
