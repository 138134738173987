import React from 'react'
import AdminLayout from '../../layout/AdminLayout'
import Classes from '../../asset/styles/AdminLaunchpadSettings.module.css'
import { Col, Row } from 'react-bootstrap'

const AdminLaunchpadSettings = () => {
  return (
    <>
    <AdminLayout>
    <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
          <h2
            className={`${Classes.launchpad__title} text-uppercase aboro_font`}
          >
            Launchpad Settings
          </h2>
          <Row>
            <Col lg={8} xl={6}>

          <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="poolCreationFee" className={`${Classes.inputLabel} orbitron`}>Pool Createion Fee</label><br/>
             <div className={`${Classes.inputHolder} mt-3 d-flex justify-content-between align-items-center`}>
              <input type="number" id="poolCreationFee" name="poolCreationFee" className={`${Classes.inputOnly} inter_font flex-grow-1 w-100`} placeholder='Ex.0' autoComplete="off" />
              <button className={`${Classes.customGradBtn} border-0 outline-0 `}>Update</button>
              </div>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="liquidityCreationFee" className={`${Classes.inputLabel} orbitron`}>Liquidity Token Creation Fee</label><br/>
             <div className={`${Classes.inputHolder} mt-3 d-flex justify-content-between align-items-center`}>
              <input type="number" id="liquidityCreationFee" name="liquidityCreationFee" className={`${Classes.inputOnly} inter_font flex-grow-1 w-100`} placeholder='Ex.0' autoComplete="off" />
              <button className={`${Classes.customGradBtn} border-0 outline-0 `}>Update</button>
              </div>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="tokenCreationFee" className={`${Classes.inputLabel} orbitron`}>Token Creation Fee</label><br/>
             <div className={`${Classes.inputHolder} mt-3 d-flex justify-content-between align-items-center`}>
              <input type="number" id="tokenCreationFee" name="tokenCreationFee" className={`${Classes.inputOnly} inter_font flex-grow-1 w-100`} placeholder='Ex.0' autoComplete="off" />
              <button className={`${Classes.customGradBtn} border-0 outline-0 `}>Update</button>
              </div>
              </div>
          </Col>
          </Row>
          </div>
        </AdminLayout>      
    </>
  )
}

export default AdminLaunchpadSettings
