/** npm import */

import React from "react";
import Marquee from "react-fast-marquee";

/** local files import */

// import "../asset/styles/HeaderMarquee.css";
import classes from "../asset/styles/HeaderMarquee.module.css";

/** code start */

const HeaderMarquee = () => {
  return (
    <div className={`${classes.marquee} d-flex align-items-center row mx-auto`}>
      <div
        className={`col-4 col-lg-2  d-flex align-items-center justify-content-center`}
      >
       <p className={`violet_sans m-0`}> Trending :</p>
      </div>
      <div className="col-8 col-lg-10 ps-0">
        {" "}
        <div className={`${classes.content}`}>
          <Marquee
            className={`${classes.marquee_body} h-100`}
            autoFill={true}
            pauseOnClick={true}
          >
            <p className={`violet_sans`}>#1 token</p>
            <p className={`violet_sans`}>#2 token</p>
            <p className={`violet_sans`}>#3 token</p>
            <p className={`violet_sans`}>#4 token</p>
            <p className={`violet_sans`}>#5 token</p>
            <p className={`violet_sans`}>#6 token</p>
            <p className={`violet_sans`}>#7 token</p>
            <p className={`violet_sans`}>#8 token</p>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default HeaderMarquee;
