import React, { useCallback, useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import Classes from '../../asset/styles/tokenCreation.module.css'
import { Images } from '../../data/Images'
import BalanceDetails from '../../components/BalanceDetails'
import { Col, Row } from 'react-bootstrap'
import Select from 'react-select';

const TokenCreation = () => {
    const options = [
        { value: 'Standard Token', label: 'Standard Token' },
        { value: 'Liquidity Generator Token', label: 'Liquidity Generator Token' },
      ];
      const [selectedToken,setSelectedToken] = useState(null)     

      useEffect(() =>{
        window.scrollTo(0,0)
      },[])
  return (
    <>
    <Layout>
    <section className="custom_section position-relative">
      <img src={Images.lighting} className={`${Classes.absLighting} img-fluid`} alt="" />
      <BalanceDetails/>
      <Row className={`mx-auto ${Classes.createToken__emptyRow}`}>
        <Col lg={{span:8,offset:2}} xs={12} className={`px-lg-0 ${Classes.dash__emptyBorder}`}>
        <div className={`${Classes.tokenCreationHolder} px-2 px-sm-3 px-md-4 px-lg-5 py-5`}>
      <h2
          className={`${Classes.launchpad__title} text-uppercase aboro_font`}
        >
          Create Token
        </h2>


        <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="presaleRate" className={`${Classes.inputLabel} orbitron`}>Token Type *</label><br/>
             <Select className={`mt-3`}
             placeholder={`Ex. Select Token Type`}
             name='token'
             classNamePrefix={`cmnSelect`}
             onChange={setSelectedToken}
        value={selectedToken ? selectedToken : null}
        options={options}
      />

              <p className={`${Classes.robotoHint} m-0 inter_font mt-2`}>Pool creation fee: 1 SOL</p>
              </div>


        <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="name" className={`${Classes.inputLabel} orbitron`}>Name *</label><br/>
              <input type="text" id="name" name="name" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex.Ethereum' autoComplete="off"/>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="symbol" className={`${Classes.inputLabel} orbitron`}>Symbol *</label><br/>
              <input type="number" id="symbol" name="symbol" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex. ETH' autoComplete="off"/>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="decimal" className={`${Classes.inputLabel} orbitron`}>Decimals *</label><br/>
              <input type="number" id="decimal" name="decimal"className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex. 18' autoComplete="off"/>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="totalSupply" className={`${Classes.inputLabel} orbitron`}>Total Supply *</label><br/>
              <input type="number" id="totalSupply" name="totalSupply" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex. 1000' autoComplete="off"/>
              </div>
                {selectedToken?.value === 'Liquidity Generator Token' && 
                <>
              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="yieldFee" className={`${Classes.inputLabel} orbitron`}>Transaction fee to generate yield (%) *</label><br/>
              <input type="number" id="yieldFee" name="yieldFee" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex. 50' autoComplete="off"/>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="liquidityFee" className={`${Classes.inputLabel} orbitron`}>Transaction fee to generate liquidity (%) *</label><br/>
              <input type="number" id="liquidityFee" name="liquidityFee" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex. 50' autoComplete="off"/>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="maxTransaction" className={`${Classes.inputLabel} orbitron`}>Maximum Transaction Amount*</label><br/>
              <input type="number" id="maxTransaction" name="maxTransaction" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex. 18' autoComplete="off"/>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="exchangeThereshold" className={`${Classes.inputLabel} orbitron`}>Exchange Thersold *</label><br/>
              <input type="number" id="exchangeThereshold" name="exchangeThereshold" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex. 18' autoComplete="off"/>
              <p className={`${Classes.robotoHint} m-0 inter_font mt-2`}>If you keep exchange threshold is lower than adding liquidity, will be frequent</p>
              </div>
              </>
              }

              <div className={`text-center`}>
                <button className={`${Classes.gradientBtn} border-0 outline-0 mt-5 px-4 px-sm-5 py-2 orbitron`}>Create Token</button>
              </div>
      </div>
            </Col>
</Row>
     
      </section>
    </Layout>
      
    </>
  )
}

export default TokenCreation
