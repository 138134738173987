import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import BalanceDetails from '../../components/BalanceDetails'
import { Images } from '../../data/Images'
import Classes from '../../asset/styles/TokenLock.module.css'
import { Col, Row } from 'react-bootstrap'
import { commonData } from '../../data/CommonData'
import { NavLink } from 'react-router-dom'
import LaunchingTable from '../../components/LaunchingTable'
import NoData from '../../components/NoData'

const TokenLock = () => {
    const [tab,setTab] = useState('All')
    // const [tableData,setTableData] = useState(commonData?.tokenLockTable)
    const [sliceValue,setSliceValue] = useState(3)
   
    useEffect(() =>{
        window.scrollTo(0,0)
      },[])
  return (
    <>
    <Layout>
    <section className="custom_section position-relative">
      <img src={Images.lighting} className={`${Classes.absLighting} img-fluid`} alt="" />
        <BalanceDetails/>

        <Row className={`mx-auto ${Classes.createToken__emptyRow} pb-5 position-relative`}>
        <Col lg={{span:8,offset:2}} xs={12} className={`px-lg-0 ${Classes.dash__emptyBorder}`}>
        <div className={`${Classes.tokenCreationHolder} px-2 px-sm-3 px-md-4 px-lg-5 py-5`}>
      <h2
          className={`${Classes.launchpad__title} text-uppercase aboro_font`}
        >
          Token Lock
        </h2>
        <Row className='mt-5'>
            <Col md={6} sm={4} className='mb-4 mb-sm-0'>
                <div className={`${Classes.tabHolder} d-flex justify-content-start align-items-center gap-4`}>
                    <button className={`${tab === 'All' ? Classes.tabBtnActive : Classes.tabBtn} poppins border-0 outline-0`} onClick={() => setTab('All')}>All</button>
                    <button className={`${tab === 'My Lock' ? Classes.tabBtnActive : Classes.tabBtn} poppins border-0 outline-0 `} onClick={() => setTab('My Lock')}>My Lock</button>
                </div>
            </Col>
            <Col md={6} sm={8}>
        <div className={`d-flex justify-content-end align-items-center w-100`}>
              <div className={`${Classes.searchBtn}`}>
                <p className={`${Classes.searchHint} d-none d-md-flex poppins`}>Search Token</p>
                <p className={`${Classes.searchHint} d-md-none`}><i class="fa-solid fa-magnifying-glass" /></p>
                
              </div>
              <input
                type="text"
                className={`${Classes.searchBar} flex-grow-1 poppins`}
              />
            </div>
        </Col>
        </Row>

{tab === 'All' &&
        <>
        {commonData?.tokenLockTable?.length <= 0 ?
    <NoData/>
    :    
   
        <>
<div className={`${Classes.cmnTableHolder} pb-3`}>
        <table className={`${Classes.tokenCmnTable} mt-5`}>
            <thead>
                <tr>
                    <th className='pb-3'>Token</th>
                    <th className='pb-3'>Amount</th>
                    <th className='pb-3'></th>
                </tr>
            </thead>
            <tbody>
                    <tr className={`${Classes.gradientBorder}`}>
                        <td className='py-2'>
                            <div className={`${Classes.tokenDetails} d-flex justify-content-start align-items-center gap-2`}>
                            <img src={Images.solana} className={`${Classes.tableTokenImg} img-fluid`} alt='' />
                            <div className={``}>
                                <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>Cat</p>
                                <p className={`${Classes.tableSubHint} poppins m-0 position-relative`}>links</p>
                            </div>
                            </div>
                            </td>
                            <td>
                            <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>100 SOL</p>
                            </td>
                            <td className={`text-end`}>
                                <NavLink to='/lock-detail' className={`poppins cmnNavLink position-relative px-3 ${Classes.tableNavLink}`}>View</NavLink>
                            </td>
                    </tr>
                </tbody>
        </table>
        </div>
        


        <div className={`text-center w-100 mt-5`}>
            <button className={`${Classes.violetBtn} px-2 px-sm-3`}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>View More Locks</p></button>
        </div>
        </>
         }
         </>
          }






{tab === 'My Lock' && 

<>
{commonData?.tokenLockTable?.length <= 0 ?
    <NoData/>
    :    
   
        <>
<div className={`${Classes.cmnTableHolder} pb-3`}>
        <table className={`${Classes.tokenCmnTable} mt-5`}>
            <thead>
                <tr>
                    <th className='pb-3'>Token</th>
                    <th className='pb-3'>Amount</th>
                    <th className='pb-3'></th>
                </tr>
            </thead>
            <tbody>
            <tr className={`${Classes.gradientBorder}`}>
                        <td className='py-2'>
                            <div className={`${Classes.tokenDetails} d-flex justify-content-start align-items-center gap-2`}>
                            <img src={Images.solana} className={`${Classes.tableTokenImg} img-fluid`} alt='' />
                            <div className={``}>
                                <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>Cat</p>
                                <p className={`${Classes.tableSubHint} poppins m-0 position-relative`}>links</p>
                            </div>
                            </div>
                            </td>
                            <td>
                            <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>15 SOL</p>
                            </td>
                            <td className={`text-end`}>
                                <NavLink to='/mylock-detail' className={`poppins cmnNavLink position-relative px-3 ${Classes.tableNavLink}`}>View</NavLink>
                            </td>
                    </tr>
                </tbody>
        </table>
        </div>
        


        <div className={`text-center w-100 mt-5`}>
            <button className={`${Classes.violetBtn} px-2 px-sm-3`}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>View More Locks</p></button>
        </div>
        </>
         }

</>

}















        </div>
        </Col>
        
        </Row>


        {/* <LaunchingTable/> */}
        </section>
    </Layout>
      
    </>
  )
}

export default TokenLock
