import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import BalanceDetails from '../components/BalanceDetails'
import { Col, Row } from 'react-bootstrap'
import Classes from '../asset/styles/MyWallet.module.css'
import { Images } from '../data/Images'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { IoIosCopy } from "react-icons/io";
import { commonData } from '../data/CommonData'
import { NavLink } from 'react-router-dom'
import NoData from '../components/NoData'

const MyWallet = () => {
    const [walletAddress,setWalletAddress] = useState('0xbC99587BB91a5B467f933916077E8961682d1937')
    const [profileURL,setProfileURL] = useState('profileURL')
    const [tab,setTab] = useState('activities')
    useEffect(() =>{
      window.scrollTo(0,0)
    },[])
  return (
    <>
      <Layout>
    <section className="custom_section position-relative">
      <img src={Images.lighting} className={`${Classes.absLighting} img-fluid`} alt="" />
      {/* <BalanceDetails/> */}
      <Row className={`mx-auto ${Classes.createToken__emptyRow} position-relative`}>
        <Col lg={{span:8,offset:2}} xs={12} className={`px-lg-0 ${Classes.dash__emptyBorder}`}>
        <div className={`${Classes.tokenCreationHolder} px-2 px-sm-3 px-md-4 px-lg-5 py-5`}>
      <h2
          className={`${Classes.launchpad__title} text-uppercase aboro_font`}
        >
          My Wallet
        </h2>
        <div className={`d-flex justify-content-start align-items-center gap-2 mt-4`}>
        <p className={`${Classes.walletHint} m-0 roboto`}>Connected as <span className={`${Classes.walletAddres}`}>{walletAddress}</span></p>
        <CopyToClipboard text={walletAddress} onCopy={() => {console.log('oiiiiiiii');}}>
        <IoIosCopy className={`${Classes.addressCopier} pointer`}/>
        </CopyToClipboard>
        </div>

        <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="name" className={`${Classes.inputLabel} orbitron`}>Profile URL *</label><br/>
             <div className={`${Classes.inputBtnHolder} d-flex justify-content-between align-items-center mt-3 w-100 px-2 py-1`}>
              <input type="text" id="profileURL" name="profileURL" className={`${Classes.inputBox} inter_font flex-grow-1 py-1`} placeholder='Ex.Ethereum' autoComplete="off" />
              <button className={`${Classes.gradientBtn} border-0 outline-0 d-flex justify-content-between align-items-center`}>
              <CopyToClipboard text={profileURL} onCopy={() => {console.log('oiiiiiiii');}}>
        <IoIosCopy className={`${Classes.addressCopier} pointer`}/>
        </CopyToClipboard>
        </button>
              </div>
              </div>

              <div className={`text-center`}>
                <button className={`${Classes.gradientTextBtn} border-0 outline-0 roboto py-2 px-5 mt-4`}>Logout</button>
              </div>

              <h2 className={`${Classes.launchpad__subTitle} text-uppercase aboro_font mt-5`}>Lock Records</h2>

              <div className={`${Classes.tabHolder} d-flex justify-content-start align-items-center gap-4 mt-5`}>
                    <button className={`${tab === 'activities' ? Classes.tabBtnActive : Classes.tabBtn} poppins border-0 outline-0`} onClick={() => setTab('activities')}>Activities</button>
                    <button className={`${tab === 'recentlyViewed' ? Classes.tabBtnActive : Classes.tabBtn} poppins border-0 outline-0 `} onClick={() => setTab('recentlyViewed')}>Recently Viewed</button>
                </div>

                <div className={`${Classes.gradientBtn} p-3 mt-4`}>
                    <Row>
                        <Col lg={4}>
                            <p className={`${Classes.rowTitle} text-uppercase roboto text-center`}>Total pool invested</p>
                            <p className={`${Classes.rowValue} text-uppercase poppins text-center mt-3`}>0.00</p>
                        </Col>
                        <Col lg={4}>
                            <p className={`${Classes.rowTitle} text-uppercase roboto text-center`}>Total pool invested</p>
                            <p className={`${Classes.rowValue} text-uppercase poppins text-center mt-3`}>0.00</p>
                        </Col>
                        <Col lg={4}>
                            <p className={`${Classes.rowTitle} text-uppercase roboto text-center`}>Total pool invested</p>
                            <p className={`${Classes.rowValue} text-uppercase poppins text-center mt-3`}>0.00</p>
                        </Col>
                    </Row>
                </div>

                <div className={`${Classes.cmnTableHolder} position-relative pb-3`}>
        <table className={`${Classes.tokenCmnTable} mt-5`}>
            <thead>
                <tr>
                    <th className='pb-3'>Name</th>
                    <th className='pb-3'>Sale Type</th>
                    <th className='pb-3'>Sale Address</th>
                    <th className='pb-3 text-end'>Action</th>
                </tr>
            </thead>
            <tbody>
                    <tr className={`${Classes.gradientBorder}`}>
                        <td className='py-2'>
                        <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>Meme of Neko</p>
                            </td>
                            <td>
                            <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>Private</p>
                            </td>
                            <td>
                            <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>0xbC99587BB91a5B467f933916077E8961682d1937</p>
                            </td>
                            <td className={`text-end`}>
                            <NavLink to='/lock-detail' className={`poppins cmnNavLink position-relative  ${Classes.tableNavLink}`}>View</NavLink>
                            </td>
                    </tr>
                    <tr className={`${Classes.gradientBorder}`}>
                        <td className='py-2'>
                        <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>Meme of Neko</p>
                            </td>
                            <td>
                            <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>Private</p>
                            </td>
                            <td>
                            <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>0xbC99587BB91a5B467f933916077E8961682d1937</p>
                            </td>
                            <td className={`text-end`}>
                            <NavLink to='/lock-detail' className={`poppins cmnNavLink position-relative  ${Classes.tableNavLink}`}>View</NavLink>
                            </td>
                    </tr>
                    <tr className={`${Classes.gradientBorder}`}>
                        <td className='py-2'>
                        <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>Meme of Neko</p>
                            </td>
                            <td>
                            <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>Private</p>
                            </td>
                            <td>
                            <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>0xbC99587BB91a5B467f933916077E8961682d1937</p>
                            </td>
                            <td className={`text-end`}>
                            <NavLink to='/lock-detail' className={`poppins cmnNavLink position-relative  ${Classes.tableNavLink}`}>View</NavLink>
                            </td>
                    </tr>
                </tbody>
                {/* <div className={`${Classes.tableNoDataHolder}`}>
                    <NoData/>
                </div> */}
        </table>
        </div>
        </div>
        </Col>
        </Row>
        </section>
        </Layout>
    </>
  )
}

export default MyWallet
