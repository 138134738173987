import React from 'react'
import { Images } from '../data/Images'
import Classes from '../asset/styles/NoData.module.css'

const NoData = () => {
  return (
    <>
      <div className={`d-flex flex-column justify-content-center align-items-center gap-3 mt-5`}>
        <img src={Images.noData} className={`${Classes.nodataImg} img-fluid`} alt='' />
        <p className={`${Classes.noDataText} m-0 text-center`}>No Data Found !</p>
      </div>
    </>
  )
}

export default NoData
