import React, { useState } from 'react'
import Classes from '../asset/styles/PrivateSalesCard.module.css'
import { Images } from '../data/Images'
// import Countdown from '../components/Countdown';
import Countdown from 'react-countdown';
import { Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const PrivateSalesCard = ({}) => {
    const renderer = ({days,hours,minutes,seconds}) => {
      return(
        <div className={`${Classes.countdownRenderer} d-flex justify-content-center align-items-center`}>
      <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 position-relative roboto`}>{days < 10 ? `0${days}` : days}</p>
      <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 position-relative roboto`}>{hours < 10 ? `0${hours}` : hours}</p>
      <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 position-relative roboto`}>{minutes < 10 ? `0${minutes}` : minutes}</p>
      <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 position-relative roboto`}>{seconds < 10 ? `0${seconds}` : seconds}</p>
      </div>
    )
    }
  return (
    <>
    <Col lg={4} md={6} className={`mb-4 ${Classes.salesCardHolder}`}>
      <div className={`${Classes.salesCard} py-3 px-4`}>
        <img src={Images.salesImg} className={`${Classes.salesCard__img} img-fluid mt-2`} alt='' />
        <button className={`${Classes.salesCard__topBtn} w-100 p-3 mt-3`}><p className={`${Classes.salesCard__gradText} roboto mx-auto text-capitalize`}>Meme of Neko</p></button>
        <ul className={`${Classes.salesCard__unorderList} mt-3`}>
          <li className={`${Classes.salesCard__lister} py-2 px-3`}>
            <p className={`${Classes.listText} ${Classes.listBeforeAlign} roboto`}>Fair Launch</p>
          </li>
          <li className={`${Classes.salesCard__lister} py-2 px-3`}>
            <div className={`d-flex justify-content-between align-items-center`}>
            <p className={`${Classes.listText} ${Classes.listBeforeAlign} roboto`}>Soft Cap</p>
            <p className={`${Classes.listText} roboto`}>10 SOL</p>
            </div>
          </li>
          <li className={`${Classes.salesCard__lister} py-2 px-3`}>
            <div className={`d-flex justify-content-between align-items-center`}>
            <p className={`${Classes.listText} ${Classes.listBeforeAlign} roboto`}>Liquidity</p>
            <p className={`${Classes.listText} roboto`}>55%</p>
            </div>
          </li>
          <li className={`${Classes.salesCard__lister} py-2 px-3`}>
            <div className={`d-flex justify-content-between align-items-center`}>
            <p className={`${Classes.listText} ${Classes.listBeforeAlign} roboto`}>Lockup Time</p>
            <p className={`${Classes.listText} roboto`}>100 Days</p>
            </div>
          </li>
        </ul>
        <div className={`${Classes.card__progressHolder}`}>
          <div className={`d-flex justify-content-between align-items-center`}>
            <p className={`${Classes.card__progressTitle} poppins`}>Progress</p>
            <p className={`${Classes.card__progressValue} roboto`}>20%</p>
          </div>
          <div className={`${Classes.card__progressBorder} mt-2`}>
<div className={`${Classes.card__progressBar} position-relative`}>
  <div className={`${Classes.card__filledProgress}`} style={{width:`20%`}}></div>
</div>
</div>
<p className={`${Classes.card__progressValue} roboto mt-2`}>15 LINEAETH raised</p>

<p className={`${Classes.card__progressTitle} mt-4 roboto text-center text-capitalize`}>Sale ends in</p>
<div className={`${Classes.card__countdownHolder} p-3 d-flex justify-content-center position-relative mt-2`}>

<Countdown date={Date.now() + 4500000} renderer={renderer} />
</div>

<div className={`d-flex justify-content-center align-items-center mt-3 mb-3`}>
  <NavLink to='/private-sale-detail'>
  <button className={`${Classes.card__blueBorderBtn} text-uppercase roboto`}>Detail</button>
  </NavLink>
</div>
         
        </div>
      </div>
      </Col>
      
    </>
  )
}

export default PrivateSalesCard
