import React from 'react'
import HeaderMarquee from './HeaderMarquee'
import Header from './Header'
import Footer from './Footer'
import { Container } from 'react-bootstrap'

const Layout = ({children}) => {
  return (
    <>
    <Container fluid className={`p-0 custom__containerFluid`}>
      <HeaderMarquee/>
      <Header/>
      {children}
      <Footer/>
      </Container>
    </>
  )
}

export default Layout
