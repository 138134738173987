import React, { useState } from 'react'
import Classes from '../../asset/styles/ResetPassword.module.css'
import { Col, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { Images } from '../../data/Images'
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ResetPassword = () => {
    const [newPassword,setNewPassword] = useState(false)
    const [confirmPassword,setConfirmPassword] = useState(false)
  return (
    <>
    <div className={`${Classes.adminLogin} position-relative`}>
      <Row className='position-relative h-100 d-flex justify-content-center align-items-center'>
          <Col lg={4}>
             
              <div className={`${Classes.loginFormHolder} p-2 p-sm-4`}>
              <div className={`text-center`}>
                  <img src={Images.logo} className={`img-fluid`} alt='' />
              </div>
              <h2
        className={`${Classes.login__title} text-uppercase aboro_font mt-4`}
      >
        Reset Password
      </h2>
      <div className={`${Classes.titleDivider}`}></div>

      <div className={`${Classes.singleInpHolder} mt-4`}>
            <label for="newPassword" className={`${Classes.inputLabel} orbitron`}>New Password</label><br/>
            <div className={`${Classes.infoInpHolder} position-relative mt-3 d-flex justify-content-between align-items-center`}>
            <input type={newPassword ? `text` : `password`} id="newPassword" name="newPassword" className={`${Classes.passwordInputBox} inter_font p-2 w-100`} autoComplete="off" />
            <button className={`${Classes.gradientBtnSmall} border-0 outline-0 px-3 py-1 me-1`} onClick={() => setNewPassword(!newPassword)}>
              {!newPassword ?
              <FaEye fontSize={18} />
              :
              <FaEyeSlash fontSize={18} />
          }
              </button>
            </div>
            </div>

      <div className={`${Classes.singleInpHolder} mt-4`}>
            <label for="confirmPassword" className={`${Classes.inputLabel} orbitron`}>Confirm Password</label><br/>
            <div className={`${Classes.infoInpHolder} position-relative mt-3 d-flex justify-content-between align-items-center`}>
            <input type={confirmPassword ? `text` : `password`} id="confirmPassword" name="confirmPassword" className={`${Classes.passwordInputBox} inter_font p-2 w-100`} autoComplete="off" />
            <button className={`${Classes.gradientBtnSmall} border-0 outline-0 px-3 py-1 me-1`} onClick={() => setConfirmPassword(!confirmPassword)}>
              {!confirmPassword ?
              <FaEye fontSize={18} />
              :
              <FaEyeSlash fontSize={18} />
          }
              </button>
            </div>
            </div>

           
            <div className={`d-flex justify-content-end w-100`}>
            <NavLink to='/login' className={`${Classes.loginNavLink} text-end`}>
            <p className={`${Classes.fogotPwHint} m-0 poppins text-end mt-3 pointer`}>Back to Login?</p>
            </NavLink>
            </div>

            <div className={`text-center mt-4`}>
              {/* <button className={`${Classes.loginGradBtn} border-0 outline-0 roboto px-5 py-2 pointer`}>Login</button> */}
              <button className={`${Classes.violetBtn} px-5`}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>Submit</p></button>
            </div>
              </div>

          </Col>
      </Row>
    </div>
  </>
  )
}

export default ResetPassword
