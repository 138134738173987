import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import Classes from '../asset/styles/TransferOwnerShip.module.css'
import { IoMdClose } from "react-icons/io";

const TransferOwnerShip = ({show,handleClose}) => {
    const [newAddress,setNewAddress] = useState('') 
  return (
    <>
      <Modal
      size='md'
      centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={`cmnModal `}
      >
        <Modal.Body>
          <div className={`${Classes.modalHeader} text-end`}>
            <button className={`${Classes.modalCloser}`} onClick={handleClose}><IoMdClose /></button>
          </div>
          <h5 className={`${Classes.modalTitle} m-0 text-center roboto mt-3 text-uppercase`}>Transfer Ownership</h5>

          <p className={`${Classes.robotoHint} m-0 roboto mt-4`}>(*) is required field.</p>


          <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="newOwnerAddress" className={`${Classes.inputLabel} orbitron`}>New Owner Address *</label><br/>
              <input type="text" id="newOwnerAddress" name="newOwnerAddress" value={newAddress} className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex.3ToGU6gtJJZ2NGTgps8h23akNn5asR9jYv1qwuVRgR2b' onChange={(e) => setNewAddress(e.target.value)} autoComplete="off" />
              </div>
<div className={`text-center w-100 mt-4`}>
              <button className={`${Classes.whiteBtn} poppins`} disabled={newAddress?.length <= 0}>Transfer Ownership</button>
              </div>
         
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TransferOwnerShip
