import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Classes from "../asset/styles/AddUserWhitelist.module.css";
import { IoMdClose } from "react-icons/io";
import { FaTrashCan } from "react-icons/fa6";
import { HiUserAdd } from "react-icons/hi";

const AddUserWhitelist = ({ show, handleClose, handleShowCSV }) => {
    

  const init = {
    walletAddress: "",
    allocatedValue: "",
  };
  const [newAddress, setNewAddress] = useState("");
  const [formData, setFormData] = useState([init]);
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };
  const handleAddUser = () => {
    setFormData([...formData, init]);
  };
  const handleRemoveUser = (index) => {
    console.log("formData", formData);
    const filterData = formData.filter((val, int) => int != index);
    setFormData(filterData);
  };
  return (
    <>
      <Modal
        size="md"
        centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={`cmnModal`}
      >
        <Modal.Body>
          <div className={`${Classes.modalHeader} text-end`}>
            <button className={`${Classes.modalCloser}`} onClick={handleClose}>
              <IoMdClose />
            </button>
          </div>
          <h5
            className={`${Classes.modalTitle} m-0 text-center roboto mt-3 text-uppercase`}
          >
            Add Whitelist Users
          </h5>

          <p className={`${Classes.robotoHint} m-0 poppins mt-4`}>
            Notes*: Enter details by manually or by uploading csv file.
          </p>

          {formData.map((item, index) => (
            <div
              className={`${Classes.singleInpHolder} d-flex justify-content-start align-items-center mt-4 gap-2`}
            >
              <input
                type="text"
                id="walletAddress"
                name="walletAddress"
                // value={formData?.walletAddress}
                className={`${Classes.inputBox} inter_font w-100 p-2`}
                placeholder="Wallet Address"
                autoComplete="off"
              />
              <input
                type="text"
                id="allocatedValue"
                name="allocatedValue"
                // value={formData?.allocatedValue}
                className={`${Classes.inputBox} inter_font w-100 p-2`}
                placeholder="Allocated LineaETH value"
                autoComplete="off"
              />
              <div
                className={`${Classes.dualBtnsHolder} d-flex justify-content-end align-items-center gap-1`}
              >
                {formData?.length != 1 && (
                  <button
                    className={`${Classes.optionWhiteBtn}`}
                    onClick={() => handleRemoveUser(index)}
                  >
                    <FaTrashCan />
                  </button>
                )}
                <button
                  className={`${Classes.optionWhiteBtn}`}
                  onClick={() => handleAddUser()}
                >
                  <HiUserAdd />
                </button>
              </div>
            </div>
          ))}

          <div className={`${Classes.fileUploaderHolder} d-flex justify-content-between align-items-center px-2 py-1 mt-4`}>
            <p className={`${Classes.fileInpHint} poppins m-0`}>Choose CSV file</p>
            <button className={`${Classes.gradientBtn} border-0 outline-0 bg-transparent poppins py-1 px-2 position-relative pointer`}>
                <input type="file" className={`${Classes.fileUploader}`} />
                Browse</button>
          </div>
          <div className={`d-flex justify-content-end align-items-center`}>
            <p className={`${Classes.robotoHint} m-0 poppins mt-3 pointer`} onClick={() => {handleClose();handleShowCSV();}} >Sample CSV file</p>
          </div>
          <div className={`text-center w-100 mt-4`}>
            <button
              className={`${Classes.whiteBtn} poppins`}
              onClick={handleClose}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>

      
    </>
  );
};

export default AddUserWhitelist;
