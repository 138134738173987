import React from 'react'
import { Modal } from 'react-bootstrap'
import Classes from '../asset/styles/SampleCSV.module.css'
import { IoMdClose } from "react-icons/io";

const SampleCSV = ({show,handleClose,handleShowAddWhiteList}) => {
  return (
    <>
       <Modal
        size="md"
        centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={`cmnModal`}
      >
        <Modal.Body>
          <div className={`${Classes.modalHeader} text-end`}>
            <button className={`${Classes.modalCloser}`} onClick={() => {handleClose();handleShowAddWhiteList()}}>
              <IoMdClose />
            </button>
          </div>
          <h5
            className={`${Classes.modalTitle} m-0 text-center roboto mt-3 text-uppercase`}
          >
            Sample CSV File
          </h5>

          <div className={`${Classes.csvHolder} mt-4 px-3 py-2`}>
          <div className={`${Classes.singleFlex} mb-2 d-flex justify-content-start gap-3 align-items-center`}>
            <p className={`${Classes.csvTitle} poppins m-0`}>Wallet Address</p>
            <p className={`${Classes.csvTitle} poppins m-0`}>Allocated SOL Value</p>
            </div>
            <div className={`${Classes.singleFlex} mb-2 d-flex justify-content-start gap-3 align-items-center`}>
            <p className={`${Classes.csvHint} poppins m-0`}>0xbC99587BB91a5B467f933916077E8961682d1937</p>
            <p className={`${Classes.csvHint} poppins m-0`}>1</p>
            </div>
            <div className={`${Classes.singleFlex} mb-2 d-flex justify-content-start gap-3 align-items-center`}>
            <p className={`${Classes.csvHint} poppins m-0`}>0xbC99587BB91a5B467f933916077E8961682d1937</p>
            <p className={`${Classes.csvHint} poppins m-0`}>1</p>
            </div>
            <div className={`${Classes.singleFlex} mb-2 d-flex justify-content-start gap-3 align-items-center`}>
            <p className={`${Classes.csvHint} poppins m-0`}>0xbC99587BB91a5B467f933916077E8961682d1937</p>
            <p className={`${Classes.csvHint} poppins m-0`}>1</p>
            </div>
          </div>

          
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SampleCSV
