import React, { useState } from "react";
import Classes from "../asset/styles/Pagination.module.css";

const Pagination = ({
  tableData,
  lengthValue,
  setLengthValue,
  setFilterArr,
}) => {
  // table slicing
  // const [pageLimit,setPageLimit] = useState(10);

  // const [paginationLength,setPaginationLength] =  useState(Array.from(Array((tableData?.length)/10).keys()))
  // console.log(paginationLength,'paginationLength');

  // const filterData = (index) =>{
  //   if(tableData?.length >= 11){
  //   setLengthValue(index + 1)
  //     setFilterArr(tableData?.slice(index * pageLimit,pageLimit * (index + 1)))
  //   }
  // }
  // const handlePrevNext = (data) => {
  //   let indexValue;
  //   if(data == 'prev') {indexValue = (lengthValue - 2)}
  //   else if(data == 'next') {indexValue = (lengthValue)}
  //   filterData(indexValue)

  // }
  return (
    <>
     
        <div
          className={`${Classes.paginatinHolder} w-100 d-flex justify-content-center mt-4`}
        >
          {/* <button
            className={`${Classes.paginationBtn} poppins py-2 px-3`}
            disabled={lengthValue <= 1}
            onClick={() => handlePrevNext("prev")}
          >
            &lt;
          </button>
          {paginationLength.map((item, index) => (
            <button
              className={`${
                index + 1 === lengthValue
                  ? Classes.paginationBtnActive
                  : Classes.paginationBtn
              } poppins py-2 px-3`}
              onClick={() => filterData(index)}
            >
              {item + 1}
            </button>
          ))}
          <button
            className={`${Classes.paginationBtn} poppins py-2 px-3`}
            disabled={lengthValue >= paginationLength.length}
            onClick={() => handlePrevNext("next")}
          >
            &gt;
          </button> */}
          <button
            className={`${Classes.paginationBtn} poppins py-2 px-3`}
          >
             &lt;
          </button>
          <button
            className={`${Classes.paginationBtn} poppins py-2 px-3`}
          >
            1
          </button>
          <button
            className={`${Classes.paginationBtn} poppins py-2 px-3`}
          >
            2
          </button>
           <button
            className={`${Classes.paginationBtn} poppins py-2 px-3`}
          >
            &gt;
          </button>
        </div>
    </>
  );
};

export default Pagination;
