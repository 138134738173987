/** npm import */

import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaWallet } from "react-icons/fa6";
import { Offcanvas, Accordion } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import { MdArrowDropDown, MdOutlineArrowRight } from "react-icons/md";
import { ImCircleDown } from "react-icons/im";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";

/** local files import */

import classes from "../asset/styles/Header.module.css";
import { Images } from "../data/Images";
import NavbarItems from "../components/NavbarItems";
import { ClassNames } from "@emotion/react";

/** code start */

const Header = () => {
  const navLinks = [
    {
      id: 1,
      icon: Images.dashboard,
      name: "Dashboard",
      linkTo: "/",
      subitems: [],
    },
    {
      id: 2,
      icon: null,
      name: "Launchpad",
      linkTo: "/launchpad",
      subitems: [
        {
          id: 1,
          name: "Create Launchpad",
          linkTo: "/create-launchpad",
        },
        {
          id: 2,
          name: "Create Fair Launch",
          linkTo: "/create-fair-launch",
        },
        {
          id: 3,
          name: "Launchpad List",
          linkTo: "/launchpad-list",
        },
        {
          id: 4,
          name: "Token Creation",
          linkTo: "/token-creation",
        },
      ],
    },
    {
      id: 3,
      icon: null,
      name: "Private Sale",
      linkTo: "/private-sale",
      subitems: [
        {
          id: 1,
          name: "Create Private Sale",
          linkTo: "/create-private-sale",
        },
        {
          id: 2,
          name: "Private Sale List",
          linkTo: "/private-sale-list",
        },
      ],
    },
    {
      id: 4,
      icon: null,
      name: "Token Lock",
      linkTo: "/token-lock",
      subitems: [
        {
          id: 1,
          name: "Create Lock",
          linkTo: "/create-lock",
        },
        {
          id: 2,
          name: "LP Token Lock",
          linkTo: "/lp-token-lock",
        },
        {
          id: 3,
          name: "Token Lock",
          linkTo: "/token-lock",
        },
      ],
    },
    {
      id: 5,
      icon: null,
      name: "Resource",
      scrollTo : 'footer'
      // linkTo: "#footer",
      // subitems: [
      //   {
      //     id: 1,
      //     name: "Resource 1",
      //     linkTo: "/",

      //   },
      // ],
    },
  ];

  const location = useLocation().pathname;
  const navigate = useNavigate()
  console.log(location);

  const [canvasShow, setCanvasShow] = useState(false);
  const handleCanvaClose = () => setCanvasShow(false);
  const handleCanvaShow = () => setCanvasShow(true);
  const [selectedNavItems, setSelectedNavItems] = useState(null);
  const [selectedNavId, setSelectedNavId] = useState(null);

  const handleChooseNavItem = (getItem) => {
    console.log('getitems', getItem)
    if (selectedNavItems === null && selectedNavId === null) {
      setSelectedNavId(getItem.id);
      setSelectedNavItems(getItem.subitems);
    } else if (selectedNavId === getItem.id) {
      setSelectedNavId(null);
      setSelectedNavItems(null);
    } else {
      setSelectedNavId(getItem.id);
      setSelectedNavItems(getItem.subitems);
    }
    // if (!selectedNavId && !selectedNavItems) {
    //   setSelectedNavId(getItem.id);
    //   setSelectedNavItems(getItem.subitems);
    // } else {
    //   setSelectedNavId(null);
    //   setSelectedNavItems(null);
    // }
    // if (getItem?.subitems) {
    //   setSelectedNavItems(getItem.subitems)
    // }
  };
  console.log('selectednavitems', selectedNavId, selectedNavItems)

  console.log("itemss", selectedNavId, selectedNavItems);

  return (
    <header className={`${classes.header}`}>
      <nav className="h-100">
        {/* start of web  */}
        <div className="d-none d-lg-block h-100">
          <div className="row mx-auto h-100">
            <div className="col-2 d-flex align-items-center justify-content-center">
              <NavLink to="/">
                <img
                  src={Images.logo}
                  alt="logo"
                  className={`${classes.logo}`}
                />
              </NavLink>
            </div>
            {navLinks.map((item, index) => (
              <div className={`${classes.col} col-2 `} key={index}>
                {/* <NavbarItems item={item} /> */}
                <div
                  className={`${classes.navbaritems} d-flex align-items-center justify-content-center h-100`}
                >
                  {item?.subitems && item?.subitems?.length ? 
                  <button
                    // to={item.linkTo}
                    className={`${classes.navbutton} ${item.name === "Launchpad"
                    ? location === "/create-launchpad" ||
                      location === "/create-launchpad" ||
                      location === "/create-fair-launch" ||
                      location === "/launchpad-list" ||
                      location === "/token-creation"
                      ? classes.active
                      : ""
                    : item.name === "Private Sale"
                      ? location === "/create-private-sale" ||
                        location === "/private-sale-list"
                        ? classes.active
                        : ""
                      : item.name === "Token Lock"
                        ? location === "/create-lock" ||
                          location === "/lp-token-lock" ||
                          location === "/token-lock"
                          ? classes.active
                          : ""
                        : item.name === "Resource"
                          ? location === "/resource-1"
                            ? classes.active
                            : ""
                          : ""
                    } d-flex align-items-center gap-2 text-decoration-none border-0 outline-0 bg-transparent`}
                    onClick={() => handleChooseNavItem(item)}
                  >
                    {item.icon && (
                      <img
                        src={item.icon}
                        alt="icon"
                        className={`${classes.icon}`}
                      />
                    )}
                    <p className={`roboto`}>
                      {item.name}
                      {item.subitems && item.subitems.length ? (
                        <MdArrowDropDown fontSize={16} />
                      ) : null}
                    </p>
                  </button> :
                  (     
                    <>    
                    {item.linkTo ?    
                    <NavLink
                      to={item.linkTo}
                      className={`${classes.navbutton} ${item.name === 'Dashboard' && item.linkTo === location ? classes.active : ''} d-flex align-items-center gap-2 text-decoration-none border-0 outline-0 bg-transparent`}
                    >
                      {item.icon && (
                        <img
                          src={item.name === 'Dashboard' && item.linkTo === location ? Images.dashboardActive : item.icon}
                          alt="icon"
                          className={`${classes.icon}`}
                        />
                      )}
                      <p className={`roboto`}>
                        {item.name}
                        {item.subitems && item.subitems.length ? (
                          <MdArrowDropDown fontSize={16} />
                        ) : null}
                      </p>
                    </NavLink>
                    : 

                    <Link  className={`pointer ${classes.scrollLink} roboto`}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                    to={item?.scrollTo}>
                      {item.name}
                    </Link> }      
                    </> 
                  )}

                  {/* {selectedNavItems && selectedNavItems?.length && selectedNavId === (item.id) ? ( */}
                  {/* {selectedNavItems && selectedNavItems?.length === (item.id) ? (

                    <>
                      <div className={`${classes.dropdown} d-none d-lg-block`}>
                        <ul>
                          <div className={`${classes.pointer}`}></div>
                          {selectedNavItems.map((subitems, index) => (
                            <li key={index} className={`roboto`}>
                              <NavLink to={subitems?.linkTo} className={`roboto`}>{subitems.name}</NavLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  ) : null} */}
                  {selectedNavId === item.id && selectedNavItems.length ?
                    <div div className={`${classes.dropdown} d-none d-lg-block`}>
                      <ul>
                        <div className={`${classes.pointer}`}></div>
                        {selectedNavItems?.map((subitems, index) => (
                          <li key={index} className={`roboto`}>
                            <button onClick={() => {navigate(subitems?.linkTo);setSelectedNavId(null);}} className={`${location == subitems?.linkTo ? classes.active :""} ${classes.navyBtns} border-0 outline-0 bg-transparent roboto w-100 text-start`}>{subitems.name}</button>
                          </li>
                        ))}
                      </ul>
                    </div> : null}

                </div>
              </div>
            ))}
          </div>
        </div>
        {/* end of web */}

        {/* start of mobile */}
        <div className={`${classes.mobileNavbar}  d-lg-none h-100 px-2`}>
          <div className="d-flex align-items-center justify-content-between h-100">
            <NavLink to="/">
              <img src={Images.logo} alt="logo" className={`${classes.logo}`} />
            </NavLink>
            <div className="d-flex align-items-center gap-3 gap-md-4">
              <button className="border-0 outline-0 bg-transparent">
                <FaWallet fill="#efefe5" fontSize={18} />
              </button>
              <button
                className="border-0 outline-0 bg-transparent"
                onClick={handleCanvaShow}
              >
                <GiHamburgerMenu fill="#efefe5" fontSize={18} />
              </button>
            </div>
          </div>
        </div>
        <Offcanvas show={canvasShow} className="custom_canvas d-lg-none">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="d-flex align-items-center justify-content-between w-100">
              <NavLink to="/" className="logo_wrap d-flex align-items-center">
                <img
                  src={Images.logo}
                  alt="logo"
                  className="img-fluid"
                  style={{ width: "120px" }}
                />
              </NavLink>
              <button
                className="d-lg-none border-0 bg-transparent outline-0"
                onClick={handleCanvaClose}
              >
                <IoMdClose fontSize={25} fill="#efefe5" />
              </button>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div>
              {navLinks.map((item, index) => (
                <div className="sidebar_menus mb-4 " key={index}>
                  <div className="sb_main_menu  d-flex align-items-center justify-content-between  px-3">
                    {item?.subitems && item?.subitems.length > 0 ? (
                      <Accordion className="sidebar_link_accordion" flush>
                        <Accordion.Item eventKey={index}>
                          <Accordion.Header
                            className={`roboto ${item.name === "Launchpad"
                              ? location === "/create-launchpad" ||
                                location === "/create-launchpad" ||
                                location === "/create-fair-launch" ||
                                location === "/launchpad-list" ||
                                location === "/token-creation"
                                ? "active"
                                : ""
                              : item.name === "Private Sale"
                                ? location === "/create-private-sale" ||
                                  location === "/private-sale-list"
                                  ? "active"
                                  : ""
                                : item.name === "Token Lock"
                                  ? location === "/create-lock" ||
                                    location === "/lp-token-lock" ||
                                    location === "/token-lock"
                                    ? "active"
                                    : ""
                                  : item.name === "Resource"
                                    ? location === "/resource-1"
                                      ? "active"
                                      : ""
                                    : ""
                              }`}
                          >
                            {item.name}
                            <div>
                              <ImCircleDown />
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="d-flex flex-column">
                            {item?.subitems.map((subItem, index) => (
                              // <NavLink
                              //   key={index}
                              //   to={subItem.linkTo}
                              //   className="d-flex  align-items-center gap-2 py-2"
                              // >
                              //   <MdOutlineArrowRight fontSize={17} />
                              //   {subItem.name}
                              // </NavLink>
                               <button  onClick={() => {navigate(subItem?.linkTo);setSelectedNavId(null);handleCanvaClose()}} className={`${location == subItem?.linkTo ? classes.active :""} ${classes.navyBtns} roboto  w-100 border-0 outline-0 bg-transparent d-flex  align-items-center gap-2 py-2`}> <MdOutlineArrowRight fontSize={17} /> {subItem.name}</button>
                            ))}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    ) : (
                      <NavLink to={item.linkTo} className="text_sm">
                        {item.name}
                      </NavLink>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        {/* end of mobile */}
      </nav>
    </header >
  );
};

export default Header;
