import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import Classes from '../../asset/styles/PrivateSaleDetail.module.css'
import { Images } from '../../data/Images'
import Countdown from 'react-countdown';
import { commonData } from '../../data/CommonData';
import { Col, Row } from 'react-bootstrap';
import Chart from 'react-apexcharts'
import AddUserWhitelist from '../../Modals/AddUserWhitelist';
import SampleCSV from '../../Modals/SampleCSV';
import RemoveWhtieListUser from '../../Modals/RemoveWhtieListUser';
import { CiGlobe } from "react-icons/ci";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { NavLink } from 'react-router-dom';

const PrivateSaleDetail = () => {
    const [steps,setSteps] = useState(1)
    const [saleType,setSaleType] = useState('public')

    // add whitelist modals states
    const [addUserWhiteList,setAddUserWhiteList] = useState(false)
    const handleShowAddWhiteList = () => setAddUserWhiteList(true)
    const handleCloseAddWhiteList = () => setAddUserWhiteList(false)

     // remove whitelist modals states
     const [removeUserWhiteList,setRemoveUserWhiteList] = useState(false)
     const handleShowRemoveWhiteList = () => setRemoveUserWhiteList(true)
     const handleCloseRemoveWhiteList = () => setRemoveUserWhiteList(false)

    // csv modal states
   const [showCSV,setShowCSV] = useState(false)
   const handleShowCSV = () => setShowCSV(true)
   const handleCloseCSV = () => setShowCSV(false)


    const renderer = ({days,hours,minutes,seconds}) => {
        return(
          <div className={`${Classes.countdownRenderer} d-flex justify-content-center align-items-center`}>
        <p className={`${Classes.card__counter} m-0 inter_font`}>{days < 10 ? `0${days}` : days}:</p>
        <p className={`${Classes.card__counter} m-0 inter_font`}>{hours < 10 ? `0${hours}` : hours}:</p>
        <p className={`${Classes.card__counter} m-0 inter_font`}>{minutes < 10 ? `0${minutes}` : minutes}:</p>
        <p className={`${Classes.card__counter} m-0 inter_font`}>{seconds < 10 ? `0${seconds}` : seconds}</p>
        </div>
      )
      }
      // chart options
      const [chatValue,setChatValue] = useState([60,30,10])
      const state = {
          
        series: [chatValue[0], chatValue[1], chatValue[2]],
        options: {
          chart: {
            width: 380,
            type: 'pie',
          },
          labels: ["Team", "Liquidity", "Marketing"],
          colors: ['#011789', '#D4E5FF', '#1A23FF'],
          stroke: {
            width: 0
        },
        legend: {
          show: false
      },
          responsive: [
          {
            breakpoint: 1200,
            options: {
              chart: {
                width: 400
              },
            }
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                width: 350
              },
            }
          },
          {
            breakpoint: 768,
            options: {
              chart: {
                width: 300
              },
            }
          },
        ]
        },
      
      
      };

      useEffect(() => {
        window.scrollTo(0,0)
      },[])
  return (
    <>
    <Layout>
    <section className="custom_section position-relative">
    <img src={Images.lighting} className={`${Classes.absLighting} img-fluid`} alt="" />
    <Row className={`mx-auto ${Classes.dash__emptyRow}`}>
      <Col lg={{span:9,offset:2}} xs={12} className={`px-lg-0 ${Classes.dash__emptyBorder}`}>
          <div className={`${Classes.countdownHolder} mt-4 position-relative`}>
          <Countdown date={Date.now() + 760000000} renderer={renderer}/>
          <div className={`${Classes.statusShower} d-flex justify-content-center align-items-center gap-1`}>
              <p className={`${Classes.saleStatus} text-uppercase aboro_font`}>coming up</p>
              <img src={Images.goldIndi} className={`${Classes.indicateImg} img-fluid mb-1`} alt='' />
          </div>
          </div>
          <div className={`${Classes.catImgBg} position-relative mt-5`}>
        <img src={Images.clip} className={`img-fluid ${Classes.leftTopClip}`} alt="" />
        <img src={Images.clip} className={`img-fluid ${Classes.leftBottomClip}`} alt="" />
        <img src={Images.clip} className={`img-fluid ${Classes.rightTopClip}`} alt="" />
        <img src={Images.clip} className={`img-fluid ${Classes.rightBottomClip}`} alt="" />
          <Row className={``}>
        <Col lg={9} md={8} xs={12} className={``}>
          <div className={`p-4`}>
          <div className={`${Classes.launchpad__nameHolder}`}>
            
          <h1
          className={`${Classes.cmnBigTitle} m-0 text-uppercase aboro_font`}>neko meme</h1>
          </div>


          <p className={`${Classes.launchpad__desc} ${Classes.responsiveWidText} m-0 text-uppercase mt-3 roboto`}>A promising meme on solana chain</p>
          <div className={`d-flex justify-content-start align-items-center gap-3 mt-3`}>
            <button className={`${Classes.socialIconBtn} d-flex justify-content-center align-items-center pointer`}>
            <CiGlobe className={`${Classes.globeImg}`} />
            </button>
            <button className={`${Classes.socialIconBtn} d-flex justify-content-center align-items-center pointer`}>
            <FaXTwitter />
            </button>
            <button className={`${Classes.socialIconBtn} d-flex justify-content-center align-items-center pointer`}>
            <FaTelegramPlane />
            </button>
            <NavLink to='/edit-private-sale'>
            <button className={`${Classes.socialIconBtn} d-flex justify-content-center align-items-center pointer`}>
            <MdEdit />
            </button>
            </NavLink>
          </div>

          <div className={`${Classes.card__progressHolder} mt-4`}>
      
<div className={`${Classes.card__progressBar} position-relative`}>
<div className={`${Classes.card__filledProgress}`} style={{width:`40%`}}></div>
</div>
<div className={`d-flex justify-content-between align-items-center`}>
      <p className={`${Classes.card__progressValue} roboto`}>10 SOL</p>
<p className={`${Classes.card__progressFullValue} roboto mt-2`}>100 SOL</p>
    </div>
  </div>

  <div className={`${Classes.singleInpHolder} mt-4`}>
           <label for="cost" className={`${Classes.inputLabel} roboto`}>Amount (Max: 0 OLS)</label><br/>
           <div className={`${Classes.infoInpHolder} position-relative mt-3 p-2 d-flex justify-content-between align-items-center`}>
            <input type="number" id="cost" name="cost" className={`${Classes.inputBox} inter_font  w-100`} autoComplete="off" />
            <button className={`${Classes.gradientBtnSmall} border-0 outline-0 px-3 py-1`}>Max</button>
            </div>
            </div>

            <button
            className={`${Classes.launchpad__blueBtn} text-uppercase roboto mt-5`}
          >
            <i class={`fa-solid fa-circle me-1 ${Classes.connectWalDots}`} />
            <i class={`fa-solid fa-circle me-3 ${Classes.connectWalDots}`} />
            Connect Wallet
          </button>

</div>
        </Col>
        <Col lg={3} md={4} xs={12} className={``}>
          <div className={`d-flex justify-content-between flex-column flex-md-column align-items-start`}>
          <img src={Images.cat} className={`img-fluid ${Classes.cattyImg} flex-grow-1`} />


          <ul className={`${Classes.salesCard__unorderList} flex-grow-1 w-100 mb-0`}>
      <li className={`${Classes.salesCard__lister} py-2 px-3`}>
        <div className={`d-flex justify-content-between align-items-center`}>
          <img src={Images.triangle} className={`img-fluid ${Classes.rotatedArrow}`} />
        <p className={`${Classes.listText} roboto`}>Premium Gem</p>
        </div>
      </li>
      <li className={`${Classes.salesCard__lister} py-2 px-3`}>
        <div className={`d-flex justify-content-between align-items-center`}>
        <img src={Images.triangle} className={`img-fluid ${Classes.rotatedArrow}`} />
        <p className={`${Classes.listText} roboto text-uppercase`}>KYC</p>
        </div>
      </li>
      <li className={`${Classes.salesCard__lister} py-2 px-3`}>
        <div className={`d-flex justify-content-between align-items-center`}>
        <img src={Images.triangle} className={`img-fluid ${Classes.rotatedArrow}`} />
        <p className={`${Classes.listText} roboto`}>Audit</p>
        </div>
      </li>
      <li className={`${Classes.salesCard__lister} py-2 px-3`}>
        <div className={`d-flex justify-content-between align-items-center`}>
        <img src={Images.triangle} className={`img-fluid ${Classes.rotatedArrow}`} />
        <p className={`${Classes.listText} roboto text-uppercase`}>safu</p>
        </div>
      </li>
      <li className={`${Classes.salesCard__lister} py-2 px-3`}>
        <div className={`d-flex justify-content-between align-items-center`}>
        <img src={Images.triangle} className={`img-fluid ${Classes.rotatedArrow}`} />
        <p className={`${Classes.listText} roboto`}>Doxx</p>
        </div>
      </li>
    </ul>
    </div>
        </Col>
      </Row>
      </div>
{/* pool status */}
<Row className={``}>
  <Col lg={4} className={``}>

 
      <div className={`${Classes.poolStatusHolder} mt-5 pt-4`}>
          {commonData?.launchpadInfoSteps.map((item,index) => 
      <div className={`${Classes.singleStep} d-flex justify-content-start align-items-start gap-3 mb-1`}>
                    <div className={`${Classes.stepIndicator} d-flex flex-column justify-content-start align-items-center gap-1`}>
                    <div className={`${steps === (index + 1) ? Classes.stepRoundActive : Classes.stepRound} rounded-5`}></div>
                    <div className={`${steps === (index + 1) ? Classes.stepLinerActive: Classes.stepLiner}`}></div>
                    </div>

                    <div className={`${Classes.stepsHintHolder} flex-grow-1`}>
                      <h5 className={`${steps === (index + 1) ? Classes.stepTitleActive : Classes.stepTitle} m-0 roboto`}>{item.title}</h5>
                      <p className={`${steps === (index + 1) ? Classes.stepHintActive : Classes.stepHint} ${Classes.stepsWidthAlign} m-0 roboto`}>{item.hint}</p>
                    </div>
                  </div>
                  )}
      </div>

      <div className={`${Classes.statusFlexHolder} ps-2 ps-sm-4 mt-5`}>
          <div className={`${Classes.statusSingleFlex} mb-4 d-flex justify-content-between align-items-center`}>
              <p className={`${Classes.flexLabel} roboto`}>Status</p>
              <p className={`${Classes.stepHint} roboto`}>Upcoming</p>
          </div>
          <div className={`${Classes.statusSingleFlex} mb-4 d-flex justify-content-between align-items-center`}>
              <p className={`${Classes.flexLabel} roboto`}>Sale Type</p>
              <p className={`${Classes.stepHint} roboto`}>Public</p>
          </div>
          <div className={`${Classes.statusSingleFlex} mb-4 d-flex justify-content-between align-items-center`}>
              <p className={`${Classes.flexLabel} roboto`}>Current Raised</p>
              <p className={`${Classes.stepHint} roboto`}>0 SOL</p>
          </div>
      </div>
      </Col>
</Row>
      </Col>

      {/* end of pool status */}
      <Col lg={2} xs={0} className={`d-none d-lg-flex`}></Col>
    </Row>

    <div className={`${Classes.customFluidContainer}`}>
    <div className={`${Classes.shapeSection}`}>
      <Row className={`mx-atuo h-100 ${Classes.borderBotMaker}`}>
        <Col sm={6} className={``}>
          <div className={`${Classes.detailPadAligner} p-3 p-sm-5 d-flex justify-content-center d-sm-block h-100`}>
        <div className={`${Classes.launchpad__nameHolder}`}>              
            <h1
            className={`${Classes.cmnBigTitle} m-0 text-uppercase aboro_font`}>information</h1>
            </div>
            </div>
        </Col>
        <Col sm={6} className={``}>
          <div className={`${Classes.detailsOlHolder} d-flex justify-content-center align-items-center  py-5 h-100 position-relative`}>
            <img src={Images.Flash} className={`${Classes.flashImg} img-fluid`} alt='' />
          <ol className={`${Classes.detailOl} ps-0`}>
            <li className={`${Classes.detailList} aboro_font`}><p className={`${Classes.detailListText} aboro_font text-uppercase ps-2 mb-4`}>project info</p></li>
            <li className={`${Classes.detailList} aboro_font`}><p className={`${Classes.detailListText} aboro_font text-uppercase ps-2 mb-4`}>tokenomics</p></li>
            <li className={`${Classes.detailList} aboro_font`}><p className={`${Classes.detailListText} aboro_font text-uppercase ps-2 `}>pool info</p></li>

          </ol>
          </div>
        </Col>
      </Row>

      <div className={`${Classes.siteInfoHolder} px-2 px-sm-4 px-md-5`}>
        <img src={Images.redCat} className={`${Classes.redCatImg} img-fluid`} alt='' />

        <ul className={`${Classes.detail__infoList} ps-0 mt-5`}>
          <li className={`${Classes.detail__infoSingleList} roboto mb-2`}>Meme of Neko is not only meme project on LineaETHana, we also provide NFT Staking</li>
          <li className={`${Classes.detail__infoSingleList} roboto mb-2`}>&#x1F310; Staking NFT link : https://nft.mekoLineaETHana.com</li>
          <li className={`${Classes.detail__infoSingleList} roboto mb-2`}>&#x1F4B0; Buy presale, Earn passive income by staking your Meko NFT in our platform &#x1F4B0;</li>
          <li className={`${Classes.detail__infoSingleList} roboto mb-2`}>&#x1F4E2; Don't miss opportunity, just presale buyer minimum 2 LINEAETH will get free Meko NFT</li>
          <li className={`${Classes.detail__infoSingleList} roboto mb-2`}>Note : just 500 limited NFT for presale buyers</li>
          <li className={`${Classes.detail__infoSingleList} roboto mb-2`}>Example :</li>
          <li className={`${Classes.detail__infoSingleList} roboto mb-2`}>if you buy 4 LineaETH, you will get 2 NFT</li>
          <li className={`${Classes.detail__infoSingleList} roboto mb-2`}>&#x2705; Mint Authority Revoked</li>
          <li className={`${Classes.detail__infoSingleList} roboto mb-2`}>&#x1F976; Freeze Authority Revoked</li>
          <li className={`${Classes.detail__infoSingleList} roboto mb-2`}>&#x1F525; LP Burn</li>
          <li className={`${Classes.detail__infoSingleList} roboto mb-2`}>&#x1F004; Partnership with big influencer on Weibo & Btok</li>
          <li className={`${Classes.detail__infoSingleList} roboto mb-2`}>&#x1F4A5; CEX List</li>
        </ul>

        <iframe className={`${Classes.iframer} my-5`} src="https://www.youtube.com/embed/pCFIt7jLl8w?si=1g6bAFsf65UK6rWS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen />

       
      </div>
      <div className={`${Classes.tokenomicsHolder}`}>
          <Row className={`${Classes.tokenomicsBoxBorder} mx-auto flex-column-reverse flex-lg-row px-0 h-100`}>
            <Col lg={8} className={`${Classes.tokenomicsBotBorder} px-0`}>
            <div className={`${Classes.statusFlexHolder} px-2 px-sm-3 px-md-4 px-lg-5 py-4 h-100 position-relative`}>
            <img src={Images.endFlash} className={`${Classes.chartFlashLeftt} img-fluid`} alt=''  />
          <div className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center`}>
              <p className={`${Classes.flexLabel} roboto mb-2`}>Address</p>
              <p className={`${Classes.stepHint} ${Classes.tokenAddress} roboto`}>3ToGU6gtJJZ2NGTgps8h23akNn5asR9jYv1qwuVRgR2b</p>
          </div>
          <div className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center`}>
              <p className={`${Classes.flexLabel} roboto  mb-2`}>Name</p>
              <p className={`${Classes.stepHint} roboto`}>Meme of Neko</p>
          </div>
          <div className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center`}>
              <p className={`${Classes.flexLabel} roboto  mb-2`}>Symbol</p>
              <p className={`${Classes.stepHint} roboto`}>MEKO</p>
          </div>
          <div className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center`}>
              <p className={`${Classes.flexLabel} roboto`}>Decimals</p>
              <p className={`${Classes.stepHint} roboto  mb-2`}>6</p>
          </div>
          <div className={`${Classes.statusSingleFlex}  d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center`}>
              <p className={`${Classes.flexLabel} roboto  mb-2`}>Total Supply</p>
              <p className={`${Classes.stepHint} roboto`}>10,000,000,000</p>
          </div>
      </div>
            </Col>
            <Col lg={4} className={`h-100 d-flex align-items-end px-0`}>
              <div className={`${Classes.tokenLeftBorder} h-100 d-flex justify-content-start justify-content-lg-center w-100 align-items-end mb-4 mb-sm-5 mb-lg-0`}>
            <div className={`${Classes.launchpad__nameHolder} `}>              
            <h1
            className={`${Classes.cmnBigTitle} m-0 text-uppercase aboro_font`}>tokenomics</h1>
            </div>
            </div>
            </Col>
          </Row>

          <div className={`${Classes.chartHolder} py-5 position-relative`}>
            <img src={Images.endFlash} className={`${Classes.chartFlashRight} img-fluid`} alt=''  />
            <Row className='justify-content-center'>
              <Col lg={10}>               
            <Row className={`h-100`}>
              <Col sm={6} className='d-flex justify-content-center d-sm-block'>
                <div className={`${Classes.chartPieHolder} position-relative mb-4 mb-sm-0`}>
                  <img src={Images.coinCat} className={`${Classes.detailCoinCat} img-fluid`} alt=''/>
          <Chart options={state.options} series={state.series} type="pie" width={500} height={500} />
                </div>
              </Col>
              <Col sm={6}>
                <div className={`${Classes.chartLegendsHolder} h-100 d-flex align-items-center justify-content-center`}>
                  <div>
                  <div className={`${Classes.singleLegend} d-flex justify-content-start align-items-center gap-4 mb-3`}>
                    <button className={`${Classes.singleLegendBtn} border-0 outline-0 orbitron px-3 px-md-4 px-lg-5 py-2`}>60%</button>
                    <p className={`${Classes.singleLegendLabel} m-0 orbitron`}>Team</p>
                  </div>

                  <div className={`${Classes.singleLegend} d-flex justify-content-start align-items-center gap-4 mb-3`}>
                    <button className={`${Classes.singleSecLegendBtn} border-0 outline-0 orbitron px-3 px-md-4 px-lg-5 py-2`}>30%</button>
                    <p className={`${Classes.singleLegendLabel} m-0 orbitron`}>Liquidity</p>
                  </div>

                  <div className={`${Classes.singleLegend} d-flex justify-content-start align-items-center gap-4 mb-3`}>
                    <button className={`${Classes.singleWhiteLegendBtn} border-0 outline-0 orbitron px-3 px-md-4 px-lg-5 py-2`}>10%</button>
                    <p className={`${Classes.singleLegendLabel} m-0 orbitron`}>Marketing</p>
                  </div>
                  </div>
                </div>
              </Col>
            </Row>
            </Col>
            </Row>
          </div>

          <div className={`${Classes.poolInfoHolder} px-2 px-sm-3 px-md-5 py-5`}>
          <div className={`${Classes.launchpad__nameHolder}`}>              
            <h1
            className={`${Classes.cmnBigTitle} m-0 text-uppercase aboro_font`}>pool info</h1>
            </div>
            <div className={`${Classes.poolFlexHolder} mt-5`}>
            <div className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}>
              <p className={`${Classes.poolLabel} roboto mb-2`}>Address</p>
              <p className={`${Classes.poolLabel} roboto`}>3ToGU6gtJJZ2NGTgps8h23akNn5asR9jYv1qwuVRgR2b</p>
          </div>

          <div className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}>
              <p className={`${Classes.poolLabel} roboto mb-2`}>Tokens For Presale</p>
              <p className={`${Classes.poolLabel} roboto`}>4,715,392,388 MEKO</p>
          </div>

          <div className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}>
              <p className={`${Classes.poolLabel} roboto mb-2`}>Tokens For Liquidity</p>
              <p className={`${Classes.poolLabel} roboto`}>2,284,607,611.986 MEKO</p>
          </div>

          <div className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}>
              <p className={`${Classes.poolLabel} roboto mb-2`}>SoftCap</p>
              <p className={`${Classes.poolLabel} roboto`}>5 SOL</p>
          </div>

          <div className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}>
              <p className={`${Classes.poolLabel} roboto mb-2`}>Start Time</p>
              <p className={`${Classes.poolLabel} roboto`}>2024.05.07 13:00 (UTC)</p>
          </div>

          <div className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}>
              <p className={`${Classes.poolLabel} roboto mb-2`}>End Time</p>
              <p className={`${Classes.poolLabel} roboto`}>2024.05.10 13:00 (UTC)</p>
          </div>

          <div className={`${Classes.statusSingleFlex} mb-4 d-flex  flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}>
              <p className={`${Classes.poolLabel} roboto mb-2`}>Listing On</p>
              <p className={`${Classes.poolLabel} roboto`}>Raydium AMM V4</p>
          </div>

          <div className={`${Classes.statusSingleFlex} mb-4 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}>
              <p className={`${Classes.poolLabel} roboto mb-2`}>Liquidity Percent</p>
              <p className={`${Classes.poolLabel} roboto`}>51%</p>
          </div>

          <div className={`${Classes.statusSingleFlex} d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center`}>
              <p className={`${Classes.poolLabel} roboto mb-2`}>Liquidity Lockup Time</p>
              <p className={`${Classes.poolLabel} roboto`}>150 days after pool ends</p>
          </div>
            </div>
          </div>

          <div className={`${Classes.ownerZoneHolder} px-2 px-sm-3 px-md-5 py-5`}>
          <div className={`${Classes.launchpad__nameHolder}`}>              
            <h1
            className={`${Classes.cmnBigTitle} m-0 text-uppercase aboro_font`}>Owner zone</h1>
            </div>

            <div className={`${Classes.radioHolder} mt-3`}>
            <label for="tokenAddress" className={`${Classes.inputLabel} roboto mb-3`}>Sale Type</label>
            <button className={`${Classes.singleRadio} d-flex justify-content-start align-items-center gap-3 mb-3 pointer`} onClick={() => setSaleType('public')}>
            <i className={`fa-solid fa-square ${saleType === 'public' ? Classes.radioCheckerActive : Classes.radioChecker}`} />
            <p className={`${Classes.radioHint}`}>Public</p>
            </button>

            <button className={`${Classes.singleRadio} d-flex justify-content-start align-items-center gap-3 pointer`} onClick={() => setSaleType('whiteList')}>
            <i className={`fa-solid fa-square ${saleType === 'whiteList' ? Classes.radioCheckerActive : Classes.radioChecker}`} />
            <p className={`${Classes.radioHint}`}>Whitelist</p>
            </button>
           </div>

           <div className={`${Classes.saleTypeBtnsHolder} mt-4`}>
            <Row className={`justify-content-center`}>
            {saleType == 'public' && 
            <Col lg={3} md={4} sm={6} className={`mb-3 mb-md-0`}>
              <button className={`${Classes.violetBtn} w-100 d-flex justify-content-center px-5`} onClick={() => setSaleType('whiteList')}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`} >Enable Whitelist</p></button>
              </Col>
}
              {saleType == 'whiteList' && 
              <>
              <Col lg={3} md={4} sm={6} className={`mb-3 mb-md-0`}>
              <button className={`${Classes.violetBtn} w-100 d-flex justify-content-center px-5`} onClick={() => handleShowAddWhiteList()}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`} >Add User to Whitelist</p></button>
              </Col>
              <Col lg={3} md={4} sm={6} className={`mb-3 mb-md-0`}>
              <button className={`${Classes.violetBtn} w-100 d-flex justify-content-center px-5`} onClick={() =>handleShowRemoveWhiteList()}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`} >Remove User from Whitelist</p></button>
              </Col>
              <Col lg={3} md={4} sm={6} className={`mb-3 mb-md-0`}>
              <button className={`${Classes.violetBtn} w-100 d-flex justify-content-center px-5`} onClick={() => setSaleType('public')}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`} >Disable Whitelist</p></button>
              </Col>
              </>
              }
            </Row>

            <Row className={`justify-content-center`}>
            <label for="tokenAddress" className={`${Classes.inputLabel} roboto mb-4 mt-5`}>Wallet Options</label>
            <Col lg={3} md={4} sm={6} className={`mb-3 mb-md-0`}>
              <button className={`${Classes.violetBtn} w-100 d-flex justify-content-center px-5`}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`} >Stop Sale</p></button>
              </Col>
              <Col lg={3} md={4} sm={6} className={`mb-3 mb-md-0`}>
              <button className={`${Classes.violetBtn} w-100 d-flex justify-content-center px-5`}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`} >Finalize</p></button>
              </Col>
              <Col lg={3} md={4} sm={6} className={`mb-3 mb-md-0`}>
              <button className={`${Classes.violetBtn} w-100 d-flex justify-content-center px-5`}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`} >Get Unsold 1 SOL</p></button>
              </Col>
              <Col lg={3} md={4} sm={6} className={`mb-3 mb-md-0`}>
              <button className={`${Classes.violetBtn} w-100 d-flex justify-content-center px-5`}><p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`} >Withdraw 1 SOL</p></button>
              </Col>
            </Row>



           
          
          
           </div>
          </div>
        </div>
      </div>
      </div>

    </section>
    </Layout>


    {/* modals */}
        <AddUserWhitelist show={addUserWhiteList} handleClose={handleCloseAddWhiteList} handleShowCSV={handleShowCSV} />
        <SampleCSV show={showCSV} handleClose={handleCloseCSV} handleShowAddWhiteList={handleShowAddWhiteList}  />
        <RemoveWhtieListUser show={removeUserWhiteList} handleClose={handleCloseRemoveWhiteList} />
    {/* end of modals */}
  </>
  )
}

export default PrivateSaleDetail
