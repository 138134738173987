import React, { useState } from 'react'
import AdminLayout from '../../layout/AdminLayout'
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Classes from '../../asset/styles/AdminProfileSettings.module.css'
import { Col, Row } from 'react-bootstrap';

const AdminProfileSettings = () => {
    const [oldPasswordType,setOldPasswordType] = useState(false)
    const [newPasswordType,setNewPasswordType] = useState(false)
  return (
    <>
      <AdminLayout>
      <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
          <h2
            className={`${Classes.launchpad__title} text-uppercase aboro_font`}
          >
            Profile Settings
          </h2>
          <Row className='align-items-end justify-content-start'>
            <Col sm={9} lg={8} xl={6} className='mb-3 mb-sm-0'>

           
          <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="email" className={`${Classes.inputLabel} orbitron`}>Email Address</label><br/>
              <input type="email" id="email" name="email" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex.John@gmail.com' autoComplete="off" />
              </div>
              </Col>
              <Col sm={3} lg={2}>
              <button className={`${Classes.gradientBtn} border-0 outline-0`}>Submit</button>
              </Col>
              </Row>


              <Row className='align-items-end justify-content-start'>
              <Col sm={9} lg={8} xl={6} className='mb-3 mb-sm-0'>
              <div className={`${Classes.singleInpHolder} mt-4`}>
              <label for="oldPassword" className={`${Classes.inputLabel} orbitron`}>Old Password</label><br/>
              <div className={`${Classes.infoInpHolder} position-relative mt-3 d-flex justify-content-between align-items-center`}>
              <input type={oldPasswordType ? `text` : `password`} id="oldPassword" name="oldPassword" className={`${Classes.passwordInputBox} inter_font p-2 w-100`} autoComplete="off" />
              <button className={`${Classes.gradientBtnSmall} border-0 outline-0 px-3 py-1 me-1`} onClick={() => setOldPasswordType(!oldPasswordType)}>
                {!oldPasswordType ?
                <FaEye fontSize={18} />
                :
                <FaEyeSlash fontSize={18} />
            }
                </button>
              </div>
              </div>
              </Col>
              <Col sm={3} lg={2}>
              <button className={`${Classes.gradientBtn} border-0 outline-0`}>Submit</button>
              </Col>
              
             

              
          </Row>
          <Row className='align-items-end justify-content-start'>
              <Col sm={9} lg={8} xl={6} className='mb-3 mb-sm-0'>
              <div className={`${Classes.singleInpHolder} mt-4`}>
              <label for="newPassword" className={`${Classes.inputLabel} orbitron`}>New Password</label><br/>
              <div className={`${Classes.infoInpHolder} position-relative mt-3 d-flex justify-content-between align-items-center`}>
              <input type={newPasswordType ? `text` : `password`} id="newPassword" name="newPassword" className={`${Classes.passwordInputBox} inter_font p-2 w-100`} autoComplete="off" />
              <button className={`${Classes.gradientBtnSmall} border-0 outline-0 px-3 py-1 me-1`} onClick={() => setNewPasswordType(!newPasswordType)}>
                {!newPasswordType ?
                <FaEye fontSize={18} />
                :
                <FaEyeSlash fontSize={18} />
            }
                </button>
              </div>
              </div>
              </Col>
              <Col sm={3} lg={2}>
              <button className={`${Classes.gradientBtn} border-0 outline-0`}>Submit</button>
              </Col>
              </Row>

          </div>
      </AdminLayout>
    </>
  )
}

export default AdminProfileSettings
