import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import { Images } from '../../data/Images'
import Classes from '../../asset/styles/CreateLock.module.css'
import BalanceDetails from '../../components/BalanceDetails'
import { Col, Row } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LaunchingTable from '../../components/LaunchingTable'
import { useLocation } from 'react-router-dom'

const CreateLock = () => {
  const location = useLocation()
  const [anotherOwner,setAnotherOwner] = useState(false)
  const [vesting,setVesting] = useState(false)
  const [customizeLock,setCustomizeLock] = useState(false)
  const [customDate,setCustomDate] = useState(new Date())
  const [date,setDate] = useState(new Date())

  const handleAddMonths = (e) => {
    const newDate = new Date().setMonth(new Date().getMonth() + e)
    setDate(new Date(newDate))
  }
  console.log("agjduadwd",date);
  useEffect(() =>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <Layout>
    <section className="custom_section position-relative">
      <img src={Images.lighting} className={`${Classes.absLighting} img-fluid`} alt="" />
      <BalanceDetails/>

      <Row className={`mx-auto ${Classes.createToken__emptyRow} pb-5`}>
        <Col lg={{span:8,offset:2}} xs={12} className={`px-lg-0 ${Classes.dash__emptyBorder}`}>
        <div className={`${Classes.tokenCreationHolder} px-2 px-sm-3 px-md-4 px-lg-5 py-5`}>
      <h2
          className={`${Classes.launchpad__title} text-uppercase aboro_font`}
        >
          Create Token Lock
        </h2>

        <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="tokenAddress" className={`${Classes.inputLabel} orbitron`}>{location.pathname == '/lp-token-lock' ? 'Token LP Address' : 'Token Address'} *</label><br/>
              <input type="text" id="tokenAddress"  name="tokenAddress" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex.3ToGU6gtJJZ2NGTgps8h23akNn5asR9jYv1qwuVRgR2b' autoComplete="off" />
              </div>
              <button className={`${Classes.singleRadio} d-flex justify-content-start align-items-center gap-2 mt-3 pointer`} onClick={() => setAnotherOwner(!anotherOwner)}>
              <i className={`fa-solid fa-square ${anotherOwner ? Classes.radioCheckerActive : Classes.radioChecker}`} />
              <p className={`${Classes.radioHint}`}>Use another owner?</p>
              </button>
              <div className={`${Classes.tokenDetails} mt-4`}>
                <div className={`d-flex justify-content-between align-items-center mb-2`}>
                  <p className={`${Classes.radioHint} m-0`}>Name :</p>
                  <p className={`${Classes.radioHint} m-0`}>SOL</p>
                </div>
                <div className={`d-flex justify-content-between align-items-center mb-2`}>
                  <p className={`${Classes.radioHint} m-0`}>Symbol :</p>
                  <p className={`${Classes.radioHint} m-0`}>SOL</p>
                </div>
                <div className={`d-flex justify-content-between align-items-center mb-2`}>
                  <p className={`${Classes.radioHint} m-0`}>Decimals :</p>
                  <p className={`${Classes.radioHint} m-0`}>18</p>
                </div>
              </div>

{/* another owner */}
{anotherOwner &&
              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="owner" className={`${Classes.inputLabel} orbitron`}>Owner</label><br/>
              <input type="text" id="owner" name="owner" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex.John' autoComplete="off" />
              </div>
               }

              {/* end of another owner */}

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="amount" className={`${Classes.inputLabel} orbitron`}>Amount</label><br/>
             <div className={`${Classes.inputHolder} mt-3 d-flex justify-content-between align-items-center`}>
              <input type="number" id="amount" name="amount" className={`${Classes.inputOnly} inter_font flex-grow-1`} placeholder='Ex.Enter Amount' autoComplete="off" />
              <button className={`${Classes.customGradBtn} border-0 outline-0 `}>Max</button>
              </div>

              <button className={`${Classes.singleRadio} d-flex justify-content-start align-items-center gap-2 mt-3 pointer`} onClick={() => setVesting(!vesting)}>
              <i className={`fa-solid fa-square ${vesting ? Classes.radioCheckerActive : Classes.radioChecker}`} />
              <p className={`${Classes.radioHint}`}>User Vesting?</p>
              </button>
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="lockUntil" className={`${Classes.inputLabel} orbitron`}>{vesting || customizeLock ? `TGE Date` : `Lock Until`} *</label><br/>
             <div className={`${Classes.durationHolder} d-flex justify-content-between align-items-center mt-3 position-relative`}>
              {customizeLock ?
              <>
              <div className={`${Classes.datePickerHolder} w-100`}>
              <DatePicker selected={customDate} dateFormat="Pp" name="startTime" showTimeSelect onChange={(date) => setCustomDate(date)} className={`roboto flex-grow-1`} />
              </div>
              <button className={`${Classes.gradBtnAbs} border-0 outline-0 `}><i class="fa-solid fa-calendar-days"/></button>
              </>
              :
<>
              <p className={`${Classes.radioHint} flex-grow-1`}>{date.toLocaleDateString()}</p>
              <div className={`${Classes.durationSelector} d-flex gap-2`}>
                <button className={`${Classes.customGradBtn} border-0 outline-0`} onClick={() => handleAddMonths(3)}>3M</button>
                <button className={`${Classes.customGradBtn} border-0 outline-0`} onClick={() => handleAddMonths(6)}>6M</button>
                <button className={`${Classes.customGradBtn} border-0 outline-0`} onClick={() => handleAddMonths(12)}>12M</button>
              </div>
              </>
               }
             </div>
              </div>

              <button className={`${Classes.outlineBtn} mt-3`} onClick={() => setCustomizeLock(!customizeLock)}>{customizeLock ? `Remove Customize Lock` : `Customize Lock`}</button>

{/* vesting content */}
{vesting && 
<>
              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="cycleDays" className={`${Classes.inputLabel} orbitron`}>Cycle (days)</label><br/>
              <input type="text" id="cycleDays" name="cycleDays" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex.John' autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="cycleReleasePercent" className={`${Classes.inputLabel} orbitron`}>Cycle Release Percent</label><br/>
              <input type="text" id="cycleReleasePercent" name="cycleReleasePercent" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='Ex.John' autoComplete="off" />
              </div>
              </>
              }

              {/* end of vesting content */}

              <button className={`${Classes.gradientBtn} border-0 outline-0 w-100 py-3 mt-5 d-flex flex-column align-items-center`}>
              <h5 className={`${Classes.stepTitle} orbitron m-0`}>Create Lock</h5>
              <p className={`${Classes.stepsDesc} poppins m-0 mt-2`}>Please exclude SPAILock's lockup address 0x2e8bAe13157A414AcA1a50E24a08c2357B2d65C0 from fees, rewards, max tx amount to start locking tokens. We don't support rebase tokens.
</p>
             </button>
        </div>
        </Col>
        </Row>

<div className={`mt-5`}>
        <LaunchingTable/>
        </div>
      </section>
    </Layout>
      
    </>
  )
}

export default CreateLock
