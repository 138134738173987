import React from 'react'
import { Modal } from 'react-bootstrap'
import Classes from '../asset/styles/AdminKYCModal.module.css'
import { IoMdClose } from "react-icons/io";
import { Images } from '../data/Images';

const AdminKYCModal = ({show,handleClose}) => {
  return (
    <>
      <Modal
      size='md'
      centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className={`cmnModal`}
      >
        <Modal.Body>
          <div className={`${Classes.modalHeader} text-end`}>
            <button className={`${Classes.modalCloser}`} onClick={handleClose}><IoMdClose /></button>
          </div>
          <h5 className={`${Classes.modalTitle} m-0 text-center roboto mt-3 text-uppercase`}>KYC</h5>
            <p className={`${Classes.identityType} m-0 roboto mt-4`}>Voter ID</p>


          <div className={`${Classes.singleInpHolder} mt-3`}>
             <label for="identity" className={`${Classes.inputLabel} orbitron`}>Identity Type</label><br/>
              <input type="text" id="identity" name="identity" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="mailId" className={`${Classes.inputLabel} orbitron`}>Mail ID</label><br/>
              <input type="text" id="mailId" name="mailId" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="city" className={`${Classes.inputLabel} orbitron`}>City</label><br/>
              <input type="text" id="city" name="city" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="religion" className={`${Classes.inputLabel} orbitron`}>Religion</label><br/>
              <input type="text" id="religion" name="religion" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="country" className={`${Classes.inputLabel} orbitron`}>Country</label><br/>
              <input type="text" id="country" name="country" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="zipCode" className={`${Classes.inputLabel} orbitron`}>Zip Code</label><br/>
              <input type="text" id="zipCode" name="zipCode" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="contactNumber" className={`${Classes.inputLabel} orbitron`}>Contact Number</label><br/>
              <input type="text" id="contactNumber" name="contactNumber" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="documentType" className={`${Classes.inputLabel} orbitron`}>Document Type</label><br/>
              <input type="text" id="documentType" name="documentType" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
             <label for="idNumber" className={`${Classes.inputLabel} orbitron`}>ID Number</label><br/>
              <input type="text" id="idNumber" name="idNumber" className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`} placeholder='' autoComplete="off" />
              </div>

              <div className={`${Classes.singleInpHolder} mt-4`}>
              <label for="idNumber" className={`${Classes.inputLabel} orbitron`}>KYC Document</label><br/>
              <div className={`${Classes.documentHolder} mt-4`}>
                <img src={Images.document} className={`${Classes.documenter}`} alt='' />
              </div>

              <button className={`${Classes.whiteBtn} mt-3`}>Download</button>
              </div>
<div className={`d-flex justify-content-center align-items-center gap-3 w-100 mt-5`}>
              <button className={`${Classes.whiteBtn} poppins`} onClick={() => handleClose()}>Approve</button>
              <button className={`${Classes.whiteBorderBtn} poppins`} onClick={() => handleClose()}>Reject</button>
              </div>
         
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminKYCModal
