import React, { useState } from 'react'
import AdminLayout from '../../layout/AdminLayout'
import { commonData } from '../../data/CommonData'
import Classes from '../../asset/styles/AdminFeeSettings.module.css'
import { Pagination } from 'react-bootstrap'
import AdminEditFeeModal from '../../Modals/AdminEditFeeModal'
import { ImPlus } from "react-icons/im";
import AdminAddFeeModal from '../../Modals/AdminAddFeeModal'
import AdminDeleteFeeModal from '../../Modals/AdminDeleteFeeModal'

const AdminFeeSettings = () => {
  const [addFee,setAddFee] = useState(false)
  const handleShowAddFee = () => setAddFee(true)
  const handleCloseAddFee = () => setAddFee(false)

  // edit fee modal

  const [editFee,setEditFee] = useState(false)
  const handleShowEditFee = () => setEditFee(true)
  const handleCloseEditFee = () => setEditFee(false)

  // delete fee modal

  const [deleteFee,setDeleteFee] = useState(false)
  const handleShowDeleteFee = () => setDeleteFee(true)
  const handleCloseDeleteFee = () => setDeleteFee(false)


  return (
    <>
    <AdminLayout>
    <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
      <div className={`d-flex justify-content-between align-items-center`}>
          <h2
            className={`${Classes.launchpad__title} text-uppercase aboro_font`}
          >
            Fee Settings
          </h2>
          <button className={`${Classes.gradientBtn} border-0 outline-0 py-1 px-2 py-sm-2 px-sm-3 pointer me-md-5`} onClick={() => handleShowAddFee()}><ImPlus /></button>
          </div>

          <div className={`${Classes.cmnTableHolder}`}>
            <table className={`${Classes.tokenCmnTable} mt-5`}>
              <thead>
                <tr>
                  <th className="pb-3">Fee Name</th>
                  <th className="pb-3">Currency Value</th>
                  <th className="pb-3">Token Value</th>
                  <th className="pb-3">Actions</th>
                </tr>
              </thead>
              <tbody>
                  <tr className={`${Classes.gradientBorder}`}>
                   
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                        BNBRaised
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                        5
                      </p>
                    </td>
                    <td>
                      <p
                        className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                      >
                        5
                      </p>
                    </td>
                    <td>
                      <div className={`d-flex justify-content-start align-items-center gap-3`}>
                     <button className={`${Classes.kycViewButton} border-0 outline-0 pointer position-relative`} onClick={() => handleShowEditFee()}>Edit</button>
                     <button className={`${Classes.kycViewButton} border-0 outline-0 pointer position-relative`} onClick={() => handleShowDeleteFee()}>Delete</button>
                     </div>
                    </td>
                  </tr>

                  <tr className={`${Classes.gradientBorder}`}>
                   
                   <td>
                     <p
                       className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                     >
                       BNBRaised
                     </p>
                   </td>
                   <td>
                     <p
                       className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                     >
                       5
                     </p>
                   </td>
                   <td>
                     <p
                       className={`${Classes.tableTokenName} poppins m-0 position-relative py-3`}
                     >
                       5
                     </p>
                   </td>
                   <td>
                     <div className={`d-flex justify-content-start align-items-center gap-3`}>
                    <button className={`${Classes.kycViewButton} border-0 outline-0 pointer position-relative`} onClick={() => handleShowEditFee()}>Edit</button>
                    <button className={`${Classes.kycViewButton} border-0 outline-0 pointer position-relative`} onClick={() => handleShowDeleteFee()}>Delete</button>
                    </div>
                   </td>
                 </tr>
              </tbody>
            </table>
          </div>
          <Pagination  />
         
          </div>
    </AdminLayout>

    {/* modal content */}
        <AdminEditFeeModal show={editFee} handleClose = {handleCloseEditFee} />
        <AdminAddFeeModal show={addFee} handleClose = {handleCloseAddFee} />
        <AdminDeleteFeeModal show={deleteFee} handleClose={handleCloseDeleteFee} />
    {/* end of modal content */}
      
    </>
  )
}

export default AdminFeeSettings
