import React from 'react'
import { Modal } from 'react-bootstrap';
import Classes from '../asset/styles/RemoveWhiteListUser.module.css'
import { IoMdClose } from "react-icons/io";
import { FaTrashCan } from "react-icons/fa6";
import { HiUserAdd } from "react-icons/hi";

const RemoveWhtieListUser = ({show,handleClose}) => {
  return (
    <>
    <Modal
      size="md"
      centered
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className={`cmnModal`}
    >
      <Modal.Body>
        <div className={`${Classes.modalHeader} text-end`}>
          <button className={`${Classes.modalCloser}`} onClick={handleClose}>
            <IoMdClose />
          </button>
        </div>
        <h5
          className={`${Classes.modalTitle} m-0 text-center roboto mt-3 text-uppercase`}
        >
          Remove Whitelist Users
        </h5>

          <div
            className={`${Classes.singleInpHolder} d-flex justify-content-start align-items-center mt-4 gap-2`}
          >
            <input
              type="text"
              id="walletAddress"
              name="walletAddress"
              className={`${Classes.inputBox} inter_font w-100 p-2`}
              placeholder="Wallet Address"
              autoComplete="off"
            />
            <div
              className={`${Classes.dualBtnsHolder} d-flex justify-content-end align-items-center gap-1`}
            >              
                <button
                  className={`${Classes.optionWhiteBtn}`}
                >
                  <FaTrashCan />
                </button>
                <button
                  className={`${Classes.optionWhiteBtn}`}
                >
                  <HiUserAdd />
                  </button>
            </div>
          </div>

        <div className={`text-center w-100 mt-4`}>
          <button
            className={`${Classes.whiteBtn} poppins`}
            onClick={handleClose}
          >
            Submit
          </button>
        </div>
      </Modal.Body>
    </Modal>

    
  </>
  )
}

export default RemoveWhtieListUser
